export default function Speaker() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3603 7.30418C14.3603 7.01324 14.1935 6.74805 13.9313 6.62201C13.669 6.49598 13.3578 6.53141 13.1306 6.71316L9.55354 9.57481H6.79154C6.37353 9.57481 6.03467 9.91367 6.03467 10.3317V14.8729C6.03467 15.2909 6.37353 15.6298 6.79154 15.6298H9.55354L13.1306 18.4914C13.3578 18.6732 13.669 18.7086 13.9313 18.5826C14.1935 18.4566 14.3603 18.1914 14.3603 17.9004V7.30418ZM10.2919 10.9227L12.8465 8.87895V16.3257L10.2919 14.2819C10.1577 14.1745 9.9909 14.1161 9.81904 14.1161H7.54842V11.0886H9.81904C9.9909 11.0886 10.1577 11.0301 10.2919 10.9227Z"
                fill="#EFF0FA"
            />
            <path
                d="M17.5749 9.38782C17.2794 9.0922 16.8002 9.09213 16.5045 9.38766C16.2089 9.6832 16.2089 10.1624 16.5044 10.458C17.072 11.0258 17.3908 11.7957 17.3908 12.5985C17.3908 13.4013 17.072 14.1712 16.5044 14.7389C16.2089 15.0345 16.2089 15.5138 16.5045 15.8093C16.8002 16.1048 17.2794 16.1048 17.5749 15.8091C18.4263 14.9575 18.9045 13.8027 18.9045 12.5985C18.9045 11.3943 18.4263 10.2394 17.5749 9.38782Z"
                fill="#EFF0FA"
            />
        </svg>
    );
}
