export default function Camera() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.9444 14.3934V11.1667C21.9444 9.41776 20.5267 8 18.7778 8H7.16667C5.41777 8 4 9.41776 4 11.1667V21.7222C4 23.4711 5.41777 24.8889 7.16667 24.8889H18.7778C20.5267 24.8889 21.9444 23.4711 21.9444 21.7222V18.4957L27.6642 22.5813C27.986 22.8111 28.4092 22.8418 28.7608 22.6609C29.1124 22.48 29.3333 22.1177 29.3333 21.7223V11.1668C29.3333 10.7714 29.1124 10.4091 28.7608 10.2282C28.4092 10.0473 27.986 10.078 27.6642 10.3078L21.9444 14.3934ZM6.11111 11.1667C6.11111 10.5837 6.5837 10.1111 7.16667 10.1111H18.7778C19.3607 10.1111 19.8333 10.5837 19.8333 11.1667V21.7222C19.8333 22.3052 19.3607 22.7778 18.7778 22.7778H7.16667C6.5837 22.7778 6.11111 22.3052 6.11111 21.7222V11.1667ZM27.2222 19.6712L22.7049 16.4446L27.2222 13.2179V19.6712Z"
                fill="#EFF0FA"
            />
        </svg>
    );
}
