export default function Exit() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.6002 25.7333C24.9008 25.7333 25.189 25.6139 25.4016 25.4014C25.6141 25.1888 25.7335 24.9006 25.7335 24.6L25.7335 8.73331C25.7335 8.43273 25.6141 8.14447 25.4016 7.93193C25.189 7.71938 24.9008 7.59998 24.6002 7.59998L20.0669 7.59998C19.4409 7.59998 18.9335 7.09257 18.9335 6.46665C18.9335 5.84072 19.4409 5.33331 20.0669 5.33331L24.6002 5.33331C25.5019 5.33331 26.3667 5.69153 27.0044 6.32915C27.642 6.96677 28.0002 7.83158 28.0002 8.73331V24.6C28.0002 25.5017 27.642 26.3665 27.0044 27.0041C26.3667 27.6418 25.5019 28 24.6002 28H20.0669C19.4409 28 18.9335 27.4926 18.9335 26.8666C18.9335 26.2407 19.4409 25.7333 20.0669 25.7333H24.6002Z"
                fill="#FFEDEC"
            />
            <path
                d="M12.9349 23.1347C12.4923 23.5773 11.7747 23.5773 11.3321 23.1347L5.66899 17.4716C5.65699 17.4597 5.64525 17.4475 5.63378 17.4351C5.54484 17.3388 5.47565 17.2308 5.42623 17.1164C5.36656 16.9785 5.3335 16.8264 5.3335 16.6666C5.3335 16.5066 5.36667 16.3543 5.42651 16.2163C5.47546 16.1031 5.54373 15.9963 5.63132 15.9009C5.64362 15.8875 5.65624 15.8743 5.66917 15.8615L11.3321 10.1986C11.7747 9.75599 12.4923 9.75599 12.9349 10.1986C13.3775 10.6412 13.3775 11.3588 12.9349 11.8014L9.20295 15.5333L20.0668 15.5333C20.6928 15.5333 21.2002 16.0407 21.2002 16.6666C21.2002 17.2926 20.6927 17.8 20.0668 17.8H9.20295L12.9349 21.5319C13.3775 21.9745 13.3775 22.6921 12.9349 23.1347Z"
                fill="#FFEDEC"
            />
        </svg>
    );
}
