import React from "react";
import ButtonWithIconProps from "./ButtonWithiconProps";
import { Link } from "react-router-dom";
import "./ButtonWithIconStyles.scss";
import Icons from "../../assets/Icons";

export default function ButtonWithIcon({ to, text }: ButtonWithIconProps) {
    return (
        <Link to={to} className="button-link btn btn_cyan">
            {text}
            <Icons.Contact height={26} width={26} />
        </Link>
    );
}
