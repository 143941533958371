export const contentListUsedTrucksSeo: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Camionetas Usadas",
        content: "",
        type: "h3",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Camionetas Usadas Colombia",
        content: (
            <p>
                En wcar entendemos que la elección de una camioneta usada de calidad es una decisión importante para
                nuestros clientes en Colombia. Es por eso que nos enorgullece ofrecer una amplia gama de camionetas de
                todos los matices, cómodas, de lujo, con platón, híbridas, exclusivas, comerciales que cumplen con los
                más altos estándares de calidad y confiabilidad. Nuestra selección incluye marcas reconocidas como
                Chevrolet, Renault, Mercedes Benz, BMW y Toyota entre otras, garantizando que nuestros clientes
                encuentren la camioneta de sus sueños. Compra tu Camioneta
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Camionetas-Usadas-Colombia-1536x1024.jpg`,
        alt: "Camionetas usadas colombia",
    },
    {
        title: "Camionetas SUV Usadas",
        content: (
            <p>
                Las camionetas SUV usadas son la elección perfecta para quienes buscan combinar estilo, espacio y
                potencia sin sacrificar la economía. En WCAR, entendemos que cada conductor tiene necesidades únicas,
                por eso ofrecemos una selección de SUV que destacan por su confiabilidad y rendimiento, garantizando una
                compra segura y transparente. Navega entre nuestras opciones y encuentra la camioneta SUV que mejor se
                adapte a tu estilo de vida y necesidades, todo con la tranquilidad de contar con el respaldo de nuestra
                filosofía de Transparencia Brutal.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/camioneta-suv.jpg`,
        alt: "Camionetas SUV Usadas, SUV Usadas",
    },
    {
        title: "Camioneta Chevrolet y Toyota Colombia: Las mejores opciones en camionetas usadas en Colombia",
        content: (
            <p>
                En wcar, sabemos que Chevrolet y Toyota son dos de las marcas más populares y confiables en el mercado
                de camionetas usadas en Colombia. Nuestra colección de camionetas Chevrolet ofrece modelos emblemáticos
                como la Chevrolet Montana, conocida por su versatilidad y rendimiento excepcional. También contamos con
                una amplia selección de camionetas Toyota, que son reconocidas por su durabilidad y capacidad todo
                terreno. Además, ofrecemos modelos 4×4 para aquellos que buscan aventuras fuera de la carretera.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Toyota-Colombia-1536x1024.jpg`,
        alt: "Toyota colombia",
    },
    {
        title: "Chevrolet Montana",
        content: (
            <p>
                Una de las camionetas con más crecimiento en ventas en el 2023, una pickup con un diseño y estilo único
                que enamora a los usuarios clásicos de Chevrolet y cumple a cabalidad con su función utilitaria y más la
                Chevrolet Montana llega con toda al país.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/chevrolet-montana-1024x631.webp`,
        alt: "chevrolet montana",
    },
    {
        title: "Ford en Colombia: Calidad y rendimiento garantizados",
        content: (
            <p>
                Ford es otra marca de renombre en el mercado de camionetas usadas en Colombia, y en wcar nos aseguramos
                de ofrecer una variedad de modelos de alta calidad de esta reconocida marca. Si estás buscando una
                camioneta potente y resistente, las camionetas Ford, incluyendo la popular Ford Raptor, son una opción
                excelente. Estos vehículos están diseñados para soportar condiciones difíciles y ofrecen un rendimiento
                excepcional en cualquier terreno.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Ford-en-Colombia-768x468.jpg`,
        alt: "Ford en Colombia",
    },
];

export const contentListUsedCoupesSeo: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "¿Por qué elegir un coupe usado?",
        content: (
            <p>
                Optar por un coupe usado es elegir un vehículo que combina elegancia y rendimiento. Los carros tipo
                coupe tienen un diseño distintivo que los hace destacar en cualquier carretera. Su estructura compacta y
                aerodinámica permite una conducción ágil, ideal para quienes tienen un estilo más deportivo. Al adquirir
                un coupe usado en wcar, estás obteniendo un vehículo de alto desempeño, con la tranquilidad de saber que
                ha sido inspeccionado y que todo lo que necesitas saber sobre su historial está a tu disposición. Este
                enfoque, de transparencia brutal, nos permite ofrecer autos que son tan atractivos como confiables,
                haciendo que tu inversión sea segura y duradera. En wcar, valoramos tu confianza, por eso siempre
                ponemos por delante la transparencia brutal.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-1.jpg`,
        alt: "¿Por qué elegir un coupe usado?",
    },
    {
        title: "Autos coupe en venta con garantía",
        content: (
            <p>
                La compra de un auto coupe usado no tiene por qué ser arriesgada ni complicada. En wcar, nos aseguramos
                de que todos los autos coupe en venta cuenten con un historial transparente y una garantía que respalde
                su calidad. Cada coupe usado disponible en wcar viene con la tranquilidad de una garantía de seis meses
                que cubre más de 3,000 piezas del vehículo, con la opción de extenderla hasta por dos años. Sabemos que
                una de las principales preocupaciones al comprar un carro usado es la posibilidad de encontrar defectos
                ocultos. Con nuestra política de transparencia brutal, te mostramos todo lo que necesitas saber sobre el
                estado real del auto, incluyendo el historial de mantenimiento y cualquier detalle relevante para tu
                decisión.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-2.jpg`,
        alt: "Autos coupe con garantía",
    },
    {
        title: "Encuentra carros coupe baratos",
        content: (
            <p>
                En wcar creemos que, en la actualidad, no tienes que sacrificar calidad por encontrar carros coupe
                baratos. En nuestra plataforma podrás encontrar modelos que combinan precio asequible con un buen
                rendimiento, lo que significa que puedes tener lo mejor de ambos mundos: un auto elegante y deportivo a
                un precio que se ajuste a tu presupuesto.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-3.jpg`,
        alt: "Carros coupe baratos",
    },
    {
        title: "Tu coupe usado, una compra segura",
        content: (
            <p>
                Si estás buscando un auto coupe usado, es esencial que hagas una compra inteligente y bien informada. En
                wcar, ofrecemos vehículos que destacan tanto por su diseño como por su seguridad y buen funcionamiento,
                garantizados mediante revisiones exhaustivas. Con nuestra garantía de seis meses, además de la opción de
                extenderla, te brindamos toda la información necesaria para que tomes una decisión con plena confianza,
                siempre respaldados por nuestra transparencia brutal.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-4.jpg`,
        alt: "Compra segura de coupe usado",
    },
];

export const contentListUsedCoupesSeo2 = [
    {
        title: "¿Qué tipo de garantía ofrecen los carros coupe usados en wcar?",
        content: (
            <p>
                En wcar, ofrecemos una garantía inicial de seis meses que cubre más de 3,000 piezas del vehículo, con la
                opción de extenderla hasta dos años.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-5.jpg`,
        alt: "Garantía para carros coupe",
    },
    {
        title: "¿Cómo puedo verificar el historial de un carro coupe usado antes de comprarlo?",
        content: (
            <p>
                Te proporcionamos acceso completo al historial del vehículo, incluyendo su mantenimiento y cualquier
                accidente previo.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-6.jpg`,
        alt: "Verificar historial de carro coupe",
    },
    {
        title: "¿Cuáles son las ventajas de comprar un coupe usado en wcar?",
        content: (
            <p>
                En wcar garantizamos que todos los carros han pasado por una revisión exhaustiva y ofrecemos total
                transparencia en el estado del vehículo.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-7.jpg`,
        alt: "Ventajas de comprar coupe usado",
    },
    {
        title: "¿Es posible financiar la compra de un carro coupe usado?",
        content: <p>Sí, contamos con opciones de financiamiento flexibles que se adaptan a tu presupuesto.</p>,
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/coupe/foto-coupe-8.jpg`,
        alt: "Financiamiento para carro coupe",
    },
];

export const contentListUsedTrucksSeo2: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Camionetas con platón: Espacio y versatilidad",
        content: (
            <p>
                Entendemos que algunos clientes necesitan una camioneta con espacio adicional para transportar carga. En
                wcar, contamos con una selección de camionetas con platón que ofrecen la combinación perfecta de
                capacidad de carga y confort. Estos vehículos son ideales para aquellos que necesitan transportar
                mercancías o equipos, brindando la versatilidad necesaria para enfrentar cualquier desafío. Entendemos
                que algunos clientes necesitan una camioneta con espacio adicional para transportar carga. En wcar,
                contamos con una selección de camionetas con platón que ofrecen la combinación perfecta de capacidad de
                carga y confort. Estos vehículos son ideales para aquellos que necesitan transportar mercancías o
                equipos, brindando la versatilidad necesaria para enfrentar cualquier desafío.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Camioneta-con-platon-768x513.jpg`,
        alt: "Camioneta con platon",
    },
    {
        title: "Camionetas chinas: Calidad a precios accesibles",
        content: (
            <p>
                Además de las marcas reconocidas, también ofrecemos camionetas chinas de alta calidad a precios
                accesibles. Estos vehículos ofrecen una excelente relación calidad-precio y son una opción popular entre
                aquellos que buscan una opción económica sin comprometer la calidad y el rendimiento. Nuestro equipo de
                expertos en WCar selecciona cuidadosamente las camionetas chinas disponibles para asegurarse de ofrecer
                a nuestros clientes solo productos confiables y duraderos.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Camioneta-china.webp`,
        alt: "Camioneta china",
    },
    {
        title: "Camionetas usadas Bogotá, encuentra la perfecta para ti",
        content: (
            <p>
                No importa dónde te encuentres en Colombia, en WCar estamos comprometidos a ayudarte a encontrar la
                camioneta usada perfecta. Nuestro servicio de ventas abarca todo el país, y si te encuentras en Bogotá,
                ofrecemos una amplia variedad de camionetas usadas disponibles en la capital. Nuestra página web fácil
                de usar te permite buscar por ubicación, marca, modelo y más, para que encuentres la camioneta adecuada
                de manera rápida y sencilla.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Camionetas-usadas-Bogota-1024x683.jpg`,
        alt: "Camionetas usadas Bogota",
    },
    {
        title: "Experiencia y compromiso en la venta de camionetas usadas",
        content: (
            <p>
                En wcar, contamos con años de experiencia en la venta de camionetas usadas en Colombia. Nuestro equipo
                de profesionales está dedicado a brindar un servicio excepcional y asesoramiento experto a nuestros
                clientes. Nos esforzamos por ofrecer transparencia en cada paso del proceso de compra, desde la
                selección hasta la entrega. Tu satisfacción es nuestra máxima prioridad.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Experiencia-compromiso.jpg`,
        alt: "Experiencia y compromiso",
    },
    {
        title: "¡Descubre la mejor selección de Camionetas Usadas en Colombia en WCar!",
        content: (
            <p>
                No pierdas la oportunidad de adquirir la camioneta usada de tus sueños en WCar. Explora nuestra amplia
                selección de camionetas de lujo, incluyendo marcas como Chevrolet, Toyota y Ford. También tenemos
                opciones de camionetas con platón y modelos chinos de calidad. Visita nuestra página web y encuentra la
                camioneta perfecta para ti en Bogotá o en cualquier parte de Colombia. ¡Te esperamos en WCar!
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/camionetasUsadas/Camioneta-Chevrolet-1536x1066.jpg`,
        alt: "Camioneta Chevrolet",
    },
];

// * Carros Hibridos------------------------------------------------------------------------------------------------------------------------------------------------

export const contentListHybridCarSeo: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Carros Híbridos Colombia",
        content: (
            <p>
                En wcar, somos expertos en el sector transaccional automotriz y estamos comprometidos con la promoción
                de tecnologías limpias en Colombia. Conoce nuestra amplia gama de carros híbridos que combinan lo mejor
                de la eficiencia y el rendimiento. Sin contar que en Colombia a la fecha de esta publicación se cuentan
                con beneficios como la excepción de pico y placa en varias ciudades a vehículos con tecnologías limpias.
                Compra tu Híbrido
            </p>
        ),
        type: "p",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Carros-Hibridos-Colombia-1536x1024.jpg`,
        alt: "Carros Hibridos Colombia",
    },
    {
        title: "Qué es un Híbrido",
        content: (
            <p>
                Los carros híbridos son vehículos que utilizan dos fuentes de energía para impulsarse: un motor de
                combustión interna y un motor eléctrico. Esta combinación permite un mayor ahorro de combustible y una
                reducción significativa en las emisiones de CO2.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Tecnologías-Limpias.jpg`,
        alt: "Tecnologías Limpias",
    },
    {
        title: "Tecnologías Vehiculares Limpias",
        content: (
            <p>
                En un mundo cada vez más consciente del medio ambiente, las tecnologías limpias se han convertido en una
                prioridad. Los carros híbridos representan una solución sostenible al combinar un motor de combustión
                interna con un motor eléctrico, reduciendo así las emisiones de gases contaminantes.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Tecnologias-Limpias-1536x1022.jpg`,
        alt: "Tecnologias Limpias",
    },
    {
        title: "Carros híbridos",
        content: (
            <p>
                En wcar, ofrecemos una amplia selección de carros híbridos de alta calidad. Nuestra variedad de modelos
                te permitirá encontrar el carro híbrido perfecto que se adapte a tus necesidades y estilo de vida.
            </p>
        ),
        type: "h3",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Vehículos Híbridos más populares",
        content: (
            <p>
                En nuestro concesionario, destacamos los vehículos híbridos más populares en Colombia. Estos modelos han
                sido aclamados por su rendimiento, eficiencia y tecnología innovadora. ¡Descubre cuál es el adecuado
                para ti! aquí
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Vehiculos-Hibridos-mas-populares-1-768x512.jpg`,
        alt: "Vehiculos hibridos mas populares",
    },
];

export const contentListHybridCarSeo2: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Hyundai Híbrido",
        content: (
            <p>
                Los carros híbridos de Hyundai combinan elegancia, tecnología y eficiencia en un solo vehículo. Con
                características impresionantes y sistemas avanzados, los modelos híbridos de Hyundai ofrecen una
                experiencia de conducción excepcional.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Hyundai-Hibrido.jpg`,
        alt: "Hyundai hibrido",
    },
    {
        title: "Toyota Híbrido",
        content: (
            <p>
                Toyota es un referente mundial en carros híbridos y en wcar te ofrecemos una amplia selección de sus
                modelos más populares. Estos vehículos son reconocidos por su calidad, confiabilidad y tecnología
                avanzada, lo que los convierte en una elección inteligente para aquellos que buscan un carro híbrido en
                Colombia.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Toyota-Hibrido-2-1024x512.jpg`,
        alt: "Toyota hibrido",
    },
    {
        title: "Susuki Híbrido",
        content: (
            <p>
                Suzuki ha incursionado en el mundo de los carros híbridos con propuestas innovadoras y llenas de estilo.
                Sus modelos híbridos destacan por su diseño moderno, economía de combustible y rendimiento confiable en
                las carreteras colombianas.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Susuki-Hibrido-2-1024x512.jpg`,
        alt: "Susuki hibrido",
    },
    {
        title: "Mazda Híbrido",
        content: (
            <p>
                Los carros híbridos de Mazda combinan diseño elegante, confort y eficiencia. Con su enfoque en la
                experiencia de conducción, Mazda ha logrado crear vehículos híbridos que ofrecen un rendimiento
                excepcional y una alta eficiencia energética.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/carrosHibridos/Mazda-Hibrido-1024x512.jpg`,
        alt: "Mazda hibrido",
    },
    {
        title: "En wcar le apuntamos a las nuevas tecnologías de energía limpia",
        content: (
            <p>
                En wcar, estamos comprometidos con brindarte información transparente y veraz sobre los carros híbridos
                en Colombia. Visítanos y descubre cómo la tecnología híbrida puede transformar tu forma de conducir de
                manera sostenible. ¡Te esperamos en nuestro concesionario!
            </p>
        ),
        type: "h2",
        imageUrl: "",
        alt: "",
    },
];

// * Hatchback ------------------------------------------------------------------------------------------------------------------------------------------------

export const contentListHatchbackCarSeo: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Hatchback Colombia",
        content: <p></p>,
        type: "p",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Hatchback-Colombia.jpg`,
        alt: "Hatchback Colombia",
    },
    {
        title: "Carros Hatchback Usados Colombia",
        content: (
            <p>
                En wcar tenemos claro que una de las razones por la que los Colombianos y Latinos eligen su vehículo, es
                por su estilo, comodidad y versatilidad. Nada como un Carro Hatchback.
                <br />
                <br />
                Conoce nuestra amplia gama de carros hatchback usados Colombia que brindan una versatilidad, espacio y
                diseño.
                <br />
                <br />
                Sin contar que en Colombia a la fecha de esta publicación se cuentan con beneficios como la excepción de
                pico y placa en varias ciudades a vehículos con tecnologías limpias.
                <br />
                <br />
                Compra tu Hatchback
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Carros-Hatchback-Usados-Colombia.jpg`,
        alt: "Carros Hatchback Usados Colombia",
    },
    {
        title: "Hatchback usados",
        content: <p></p>,
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Hatchback-usados.jpg`,
        alt: "Carros Hatchback Usados Colombia",
    },
    {
        title: "Un Hatchback la mejor opción en Bogotá",
        content: (
            <p>
                Si buscas un auto práctico en Bogotá, considera un hatchback usado. Son ideales para la ciudad como la
                nuestra, ofrecen buen consumo de combustible y son fáciles de estacionar. Encuentra una gran variedad de
                opciones asequibles y confiables en el mercado local.
                <br />
                <br />
                Los carros hatchback usados son una opción popular y atractiva para quienes buscan un vehículo práctico
                y versátil. Estos automóviles compactos se caracterizan por su diseño de carrocería que incluye una
                puerta trasera levadiza, lo que facilita el acceso al espacioso compartimento de carga.
                <br />
                <br />
                Su tamaño más reducido los convierte en una elección ideal para la conducción en entornos urbanos, ya
                que permiten una mejor maniobrabilidad y estacionamiento en espacios reducidos. Además, suelen ofrecer
                un rendimiento eficiente en cuanto a consumo de combustible, lo que resulta atractivo para aquellos que
                buscan economizar en gastos de combustible.
                <br />
                <br />
                Con una amplia variedad de modelos y marcas disponibles en el mercado de autos usados, los hatchbacks
                ofrecen una opción asequible y confiable para aquellos que desean un automóvil con estilo y
                funcionalidad en un paquete compacto.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Un-Hatchback-la-mejor-opción-en-Bogotá.jpg`,
        alt: "Un Hatchback la mejor opción en Bogotá",
    },
    {
        title: "Los Mejores Hatchback en Colombia",
        content: (
            <p>
                En wcar, ofrecemos una amplia selección de carros hatchback de alta calidad con nuestra garantía light o
                la plus. Nuestra variedad de modelos te permitirá encontrar los mejores hatchback en Colombia, llegando
                al carro perfecto que se adapte a tu style, a continuación te mostramos los Hatchback más populares
                entre la audiencia.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Los-Mejores-Hatchback-en-Colombia.jpg`,
        alt: "Los Mejores Hatchback en Colombia",
    },
];

export const contentListHatchbackCarSeo2: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Hatchback Colombia en wcar",
        content: <p></p>,
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/hatchback/Hatchback-Colombia.jpg`,
        alt: "Hatchback Colombia en wcar",
    },
    {
        title: "Hatchback Carros versátiles para la ciudad",
        content: (
            <p>
                En nuestro concesionario, destacamos los vehículos hatchback carros versátiles y más populares en
                Colombia. Estos modelos han sido aclamados por su rendimiento, eficiencia y Diseño. ¡Descubre cuál es el
                adecuado para ti! aquí
            </p>
        ),
        type: "h2",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Kia Rio Hatchback",
        content: (
            <p>
                El Kia Rio Hatchback es un automóvil compacto que combina estilo y funcionalidad. Con un diseño moderno
                y atractivo, ofrece una experiencia de conducción cómoda y ágil, perfecta para la vida urbana.
                <br />
                <br />
                Su tamaño compacto lo hace ideal para maniobrar en espacios reducidos y encontrar estacionamiento
                fácilmente en áreas concurridas como Bogotá. Además, cuenta con un espacioso interior que ofrece
                comodidad para los pasajeros y un generoso espacio de carga en su maletero.
                <br />
                <br />
                El Kia Rio Hatchback está equipado con tecnología avanzada, características de seguridad sólidas y un
                rendimiento eficiente en cuanto a consumo de combustible, convirtiéndolo en una excelente opción para
                aquellos que buscan un hatchback versátil y confiable en la ciudad.
            </p>
        ),
        type: "h3",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Chevrolet Onix Hatchback",
        content: (
            <p>
                El Chevrolet Onix Hatchback es un vehículo compacto y dinámico que combina estilo y practicidad. Con un
                diseño moderno y audaz, destaca en el camino y llama la atención.
                <br />
                <br />
                Su interior espacioso y bien diseñado ofrece comodidad para todos los ocupantes, junto con un generoso
                espacio de carga en el maletero. Perfecto para la vida urbana, el Chevrolet Onix Hatchback se desliza
                ágilmente por las calles estrechas y congestas de la ciudad.
                <br />
                <br />
                Además, cuenta con tecnología avanzada que mejora la conectividad y la seguridad, brindando una
                experiencia de conducción más placentera y segura. Con un eficiente consumo de combustible y un
                rendimiento confiable, el Chevrolet Onix Hatchback es una opción atractiva para aquellos que buscan un
                automóvil versátil y asequible para sus desplazamientos diarios en Bogotá.
            </p>
        ),
        type: "h3",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Hyundai Accent Hatchback",
        content: (
            <p>
                El Hyundai Accent Hatchback es un automóvil compacto que destaca por su estilo moderno y elegante. Su
                diseño aerodinámico y líneas bien definidas le otorgan una presencia atractiva en la carretera.
                <br />
                <br />
                Además de su aspecto llamativo, ofrece un interior espacioso y confortable, con asientos cómodos y una
                buena cantidad de espacio para los pasajeros y el equipaje. Su tamaño compacto lo hace ideal para la
                conducción en entornos urbanos, permitiendo maniobrar con facilidad y encontrar estacionamiento sin
                problemas.
                <br />
                <br />
                El Hyundai Accent Hatchback también cuenta con una variedad de características tecnológicas y de
                seguridad, proporcionando una experiencia de conducción segura y conectada.
                <br />
                <br />
                Con un rendimiento eficiente en cuanto a consumo de combustible, este hatchback es una opción atractiva
                para aquellos que buscan un automóvil fiable y versátil para sus desplazamientos diarios.
            </p>
        ),
        type: "h3",
        imageUrl: "",
        alt: "",
    },
    {
        title: "Mazda 3 Hatchback",
        content: (
            <p>
                El Mazda 3 Hatchback es un automóvil compacto que se destaca por su elegante diseño y su enfoque en la
                experiencia de conducción. Con líneas fluidas y una apariencia sofisticada, este hatchback cautiva con
                su estilo moderno y distintivo.
                <br />
                <br />
                El interior del Mazda 3 Hatchback ofrece un ambiente refinado y bien construido, con materiales de alta
                calidad y asientos cómodos que brindan una sensación de lujo. Su conducción ágil y precisa, junto con su
                suspensión bien ajustada, hacen que cada viaje sea placentero y emocionante.
                <br />
                <br />
                Además de su rendimiento dinámico, este automóvil también viene equipado con tecnología avanzada,
                características de seguridad destacadas y una conectividad intuitiva, brindando una experiencia de
                conducción completa y satisfactoria. Si buscas un hatchback que combine estilo, desempeño y comodidad,
                el Mazda 3 Hatchback es una opción a considerar en Bogotá.
            </p>
        ),
        type: "h3",
        imageUrl: "",
        alt: "",
    },
];

// Sedan -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

export const contentListSedanCarSeo: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Carros Sedan Usados",
        content: "",
        type: "p",
        imageUrl: "",
        alt: "",
    },
    {
        title: "¿Estás buscando un carro sedan usado confiable y a un precio accesible?",
        content: (
            <p>
                En wcar, entendemos tus necesidades y estamos aquí para ayudarte a encontrar el vehículo ideal que se
                ajuste a tus gustos y presupuesto. Con nuestra amplia experiencia en el mercado de carros usados, nos
                hemos consolidado como el concesionario líder en en el país, ofreciendo una amplia selección de carros
                sedan Colombia usados de alta calidad.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Carros-sedan-Usados.png`,
        alt: "",
    },
    {
        title: "Compra tu Sedan",
        content: (
            <p>
                Cuando se trata de carros sedan Colombia usados, sabemos que la confianza y la transparencia son
                fundamentales. En wcar, nos comprometemos a brindarte la mejor experiencia de compra posible. Todos
                nuestros carros sedan usados han pasado por rigurosas inspecciones y pruebas para garantizar su calidad
                y confiabilidad.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Compra-tu-sedan.jpg`,
        alt: "",
    },
    {
        title: "Carros Sedan Colombia",
        content: (
            <p>
                Además, te proporcionamos un informe detallado del historial del vehículo, que incluye información sobre
                accidentes anteriores, reparaciones y mantenimiento realizado.
                <br />
                Puedes visitar nuestro concesionario en cualquier momento para explorar nuestra selección de carros
                sedan usados y recibir asesoramiento personalizado de nuestro equipo de especialistas. También puedes
                visitar nuestro catálogo en tucarro.com o visitar nuestro sitio web para ver el inventario en línea y
                solicitar más información.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Carros-Sedan-Colombia.jpg`,
        alt: "",
    },
    {
        title: "¿Qué es un Sedan?",
        content: (
            <p>
                Un carro sedan es un tipo de vehículo de cuatro puertas, diseñado para ofrecer comodidad, espacio y
                versatilidad. Se caracteriza por tener un compartimento de carga separado y una carrocería más larga que
                otros tipos de carros.
                <br />
                <br />
                Los carros sedan son ideales para familias y personas que buscan un equilibrio entre estilo,
                funcionalidad y rendimiento. Ofrecen asientos cómodos tanto para el conductor como para los pasajeros, y
                su diseño aerodinámico brinda una conducción suave y eficiente. Además, los carros sedan suelen ser
                conocidos por su elegancia y sofisticación, lo que los convierte en una opción popular en el mercado
                automotriz.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Qué-es-un-Sedan.jpg`,
        alt: "",
    },
    {
        title: "Compra Sedan Usado",
        content: (
            <p>
                El mercado de carros usados ofrece una amplia variedad de opciones para aquellos que buscan un vehículo
                confiable y asequible. Si estás considerando comprar un carro sedan usado, hay varios aspectos
                importantes que debes tener en cuenta antes de tomar una decisión.
                <br />
                <br />
                En wcar, exploraremos las ventajas para que realices la compra sedan usado, las consideraciones clave
                antes de realizar la compra, cómo evaluar y elegir el carro adecuado, consejos para negociar el precio,
                financiamiento y seguro, y el mantenimiento necesario para mantener tu nuevo vehículo en buen estado.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Compra-Sedan-Usado.jpg`,
        alt: "",
    },
];

export const contentListSedanCarSeo2: {
    title: string;
    content: string | React.ReactNode;
    type: string;
    imageUrl: string;
    alt: string;
}[] = [
    {
        title: "Conoce el Auto Sedan más popular",
        content: (
            <p>
                En nuestro concesionario, destacamos los vehículos Sedan más populares en Colombia. Estos modelos han
                sido aclamados por su rendimiento, eficiencia y tecnología innovadora. ¡Descubre cuál es el adecuado
                para ti! aquí como el Mazda 2 Sedan, Chevrolet es popular con el Joy Sedan, el Aveo Sedan el Onix Sedan,
                el Kia Rio Sedan no se queda atrás y exponemos algunas de sus grandes características.
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Estás-buscando-un-carro-sedan-usado-confiable.jpg`,
        alt: "",
    },
    {
        title: "Mazda 2 Sedan",
        content: (
            <p>
                El Mazda 2 Sedán es un automóvil compacto que combina estilo y desempeño de manera excepcional. Con un
                diseño elegante y aerodinámico, este vehículo ofrece líneas suaves y dinámicas que le dan una apariencia
                moderna y sofisticada. Su tamaño compacto lo hace ideal para maniobrar en espacios reducidos, mientras
                que su amplio interior brinda comodidad y espacio suficiente para los ocupantes.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Masda-Sedan.jpg`,
        alt: "",
    },
    {
        title: "Kia Rio Sedan",
        content: (
            <p>
                El Kia Rio Sedán es un automóvil compacto que destaca por su diseño moderno y atractivo, combinado con
                un rendimiento sólido y características avanzadas. Con líneas dinámicas y detalles estilizados, este
                sedán ofrece una apariencia elegante y sofisticada.
                <br />
                <br />
                Su amplio interior proporciona comodidad y espacio para los ocupantes, mientras que su maletero generoso
                permite llevar carga adicional.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}`,
        alt: "",
    },
    {
        title: "Chevrolet Joy Sedan",
        content: (
            <p>
                El Chevrolet Joy Sedán es un vehículo versátil y confiable que se destaca por su diseño atractivo y su
                eficiencia en el consumo de combustible. Con líneas elegantes y modernas, este sedán compacto ofrece un
                amplio espacio interior y un maletero generoso, lo que lo convierte en una opción ideal para aquellos
                que buscan comodidad y practicidad.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Chevrolet-Joy-Sedan.png`,
        alt: "",
    },
    {
        title: "Chevrolet Onix Sedan",
        content: (
            <p>
                El Chevrolet Onix Sedán es un automóvil compacto que combina estilo, eficiencia y tecnología de manera
                excepcional. Con un diseño moderno y aerodinámico, este sedán destaca por sus líneas elegantes y
                detalles sofisticados. Su amplio interior brinda comodidad y espacio suficiente para los ocupantes,
                mientras que su maletero espacioso permite transportar carga adicional.
            </p>
        ),
        type: "h3",
        imageUrl: `${process.env.PUBLIC_URL}/assets/pages/Sedan/Chevrolet-Onix-Sedan.png`,
        alt: "",
    },
    {
        title: "Conoce nuestros Carros Usados Sedan disponibles",
        content: (
            <p>
                En wcar, estamos comprometidos con brindarte información brutalmente transparente y veraz sobre los
                carros Sedan en Colombia. Visítanos y descubre cómo puede cambiar tu vida un vehículo familiar diseñado
                para suplir tus necesidades. ¡Te esperamos en nuestro concesionario!
            </p>
        ),
        type: "h2",
        imageUrl: `${process.env.PUBLIC_URL}`,
        alt: "",
    },
];
