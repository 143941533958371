import "./cardFinancingStyles.scss";
import Check from "./check.png";
import Zigs from "./lines_zigs.png";
import Time from "./time.png";

export const CardFinancing = ({ data }) => {
    return (
        <div className="banner_financing">
            <div className="container h-100 gap-4 d-flex align-items-center justify-content-around position-relative flex-wrap">
                <div>
                    <p className="title">
                        Financia tu
                        <br />
                        <i>Vehículo</i>
                    </p>
                </div>
                <div className="d-flex justify-content-center flex-wrap gap-5 z-1">
                    {data.map((data: any, i: number) => {
                        return (
                            <div key={i} className="card_financing">
                                <div className="title_container">
                                    <p style={{color: data.colorOne}}>
                                        {data.titlePartOne}<i style={{color: data.colorTwo}}>{data.titlePartTwo}</i>
                                    </p>
                                </div>
                                <div className="sub_title_container">
                                    <img src={data.img} alt="wcar" style={{ width: "25px", marginTop: "4px"}} />
                                    <p><strong>{data.subTitle}</strong></p>
                                </div>
                                <div className="item_container">
                                    <p>
                                        <img src={Check} alt="wcar" style={{ width: "20px" }} /> {data.paragraphOne}
                                    </p>
                                    <p>
                                        <img src={Check} alt="wcar" style={{ width: "20px" }} /> {data.paragraphTwo}
                                    </p>
                                    <p>
                                        <img src={Check} alt="wcar" style={{ width: "20px" }} /> {data.paragraphThree}
                                    </p>
                                </div>
                                <div className="button_container">
                                    <button className="btn-custom" onClick={() => data?.action()}>
                                        {data.button}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <img src={Zigs} alt="wcar" className="position-absolute top-0 start-0" />
            </div>
        </div>
    );
};
