import { FC } from "react";
import Layout from "../../layout/Layout";

const BuyerPolicies: FC<{}> = () => {
    return (
        <Layout>
            <div
                className="w-100 px-3 p_spaced"
                style={{ backgroundImage: "url(/assets/pages/contact/bg_contact.jpg)", backgroundSize: "contain" }}
            >
                <div className="container">
                    <div className="bg-white p-5">
                        <div className="side side_top mb-3">
                            <h2>TÉRMINOS Y CONDICIONES PLATAFORMA WCAR </h2>
                        </div>
                        <p>
                            El presente documento contiene los términos y condiciones que regirán la relación comercial
                            entre WCAR S.A.S. (en adelante “WCAR”) y el COMPRADOR, en relación con los servicios
                            ofrecidos por WCAR a través de sus soluciones tecnológicas (“Plataformas Tecnológicas”).
                        </p>
                        <p>
                            Se le informa a quienes ingresen o se registren en la Plataforma Tecnológica (en adelante
                            los “Usuarios” o el “Usuario”), que el acceso a la misma implica el conocimiento,
                            comprensión y aceptación de los términos y condiciones generales de uso (“Términos y
                            Condiciones”) previstos en este documento. En caso de que el Usuario no esté de acuerdo,
                            deberá abstenerse de utilizar la Plataforma Tecnológica.
                        </p>
                        <p>
                            WCAR se reserva el derecho de actualizar o modificar los presentes Términos y Condiciones,
                            en cualquier momento en que lo considere necesario. Estas modificaciones estarán disponibles
                            al público a través de la página web wcar.co.
                        </p>

                        <h3 className="mb-3">I. DEFINICIONES</h3>
                        <p>
                            <strong>Comprador:</strong> Persona natural o jurídica que realiza la reserva y adquisición
                            del vehículo a través de la Plataforma Tecnológica de propiedad de WCAR.
                        </p>
                        <p>
                            <strong>Plataforma tecnológica:</strong> Hace referencia al desarrollo tecnológico que
                            despliega WCAR a favor del COMPRADOR, a través de una página web y aplicación móvil
                            denominada Wcar.co que permite vincular a personas interesadas en vender un vehículo con
                            aquellas interesadas en adquirirlo por medio de un contrato de corretaje. Adicionalmente,
                            permite prestar servicios de trámites de traspaso, intermediación en la consecución de
                            créditos, entre otros.
                        </p>
                        <p>
                            <strong>Usuario:</strong> Se refiere a la separación del vehículo que hace el COMPRADOR con
                            la finalidad de que WCAR confirme la disponibilidad de éste para la venta inmediata.
                        </p>
                        <p>
                            <strong>Vendedor:</strong> Persona natural o jurídica que tiene la calidad de propietario de
                            un vehículo y desea venderlo a través de la Plataforma tecnológica de WCAR, en virtud de un
                            contrato de corretaje.
                        </p>

                        <h3 className="mb-3">II. DECLARACIONES</h3>
                        <ul>
                            <li>
                                WCAR.CO (en adelante “WCAR” o “LA PLATAFORMA”) es una Plataforma tecnológica operada por
                                WCAR S.A.S. sociedad identificada con NIT 901.566.049-8, con la finalidad de vincular a
                                personas interesadas en vender un vehículo con aquellas interesadas en adquirirlo por
                                medio de un contrato de corretaje. Adicionalmente, permite prestar servicios de trámites
                                de traspaso, intermediación en la consecución de créditos, entre otros.
                            </li>

                            <li>
                                El COMPRADOR declara conocer y aceptar que (i) los vehículos ofrecidos en venta no son
                                de propiedad de WCAR, sino de terceros interesados en venderlos exclusivamente a través
                                de la Plataforma tecnológica; (ii) WCAR actuará como corredor entre el VENDEDOR y EL
                                COMPRADOR; (iii) el vehículo es usado y que por lo tanto puede ser vendido sin ningún
                                tipo de garantía
                            </li>
                            <li>
                                El COMPRADOR acepta que no realizará un negocio jurídico de compraventa con WCAR sino
                                con el propietario y vendedor del vehículo, utilizando los servicios e intermediación
                                digital de WCAR.
                            </li>
                            <li>
                                El vehículo que el COMPRADOR está interesado en adquirir es el que se encuentra descrito
                                en las condiciones generales del contrato.
                            </li>
                        </ul>

                        <h3 className="mb-3">III. OBLIGACIONES DE LAS PARTES. </h3>
                        <ul>
                            <li>
                                WCAR prestará en favor del COMPRADOR los siguientes servicios: (i) a través de su
                                Plataforma tecnológica, permite que el COMPRADOR obtenga información de vehículos
                                disponibles para la venta; (ii) permitir que el COMPRADOR pueda realizar la reserva del
                                vehículo escogido; (iii) confirmar al COMPRADOR, a través de comunicación electrónica
                                y/o cualquier otro medio que WCAR considere pertinente, la disponibilidad del vehículo
                                reservado para la venta inmediata; (v) ofrecer servicios adicionales relacionados con
                                trámites de traspaso, intermediación en la consecución de créditos, y cualquier trámite
                                necesario para formalizar la compraventa del vehículo.{" "}
                            </li>
                            <li>
                                El COMPRADOR se compromete a: (i) pagar el valor de la reserva del vehículo establecida
                                en la Cláusula Cuarta; (ii) en caso de estar disponible, pagar el valor de venta
                                establecido en la Cláusula Sexta.; (iii) firmar la documentación requerida para efectuar
                                el trámite de traspaso del vehículo; (iv) abstenerse de contactar por cualquier medio al
                                VENDEDOR sin autorización expresa de WCAR.
                            </li>
                        </ul>
                        <h3 className="mb-3">IV. RESERVA DEL VEHICULO. </h3>
                        <ul>
                            <li>
                                El COMPRADOR realizará la reserva del vehículo a través de la Plataforma tecnológica, y
                                deberá inmediatamente realizar el pago de ($_____________________) o un valor superior
                                si así lo desea, los cuales deberán ser consignados según las formas de pago dispuestas
                                por WCAR para tal fin. Este valor se entenderá como un abono al pago del precio final de
                                venta a título de arras confirmatorias.
                            </li>
                            <li>
                                La reserva del vehículo no es una oferta vinculante ni elimina el vehículo de la oferta
                                disponible en la Plataforma tecnológica, por lo tanto, WCAR se reserva el derecho de
                                confirmar la disponibilidad en un plazo no superior a 24 horas, contadas a partir de la
                                recepción del pago por parte del COMPRADOR. En caso de no estar disponible, WCAR
                                efectuará la devolución del valor pagado por concepto de reserva, dentro de los dos (2)
                                días hábiles siguientes a la notificación de no disponibilidad.
                            </li>
                            <li>
                                En caso de que el COMPRADOR haga la reserva directamente a través de la plataforma y
                                desee ejercer el Derecho de retracto consagrado en el artículo 47 de la ley 1480 de
                                2011, deberá hacerlo dentro de los cinco (5) días hábiles posteriores a la reserva.
                            </li>
                            <li>
                                Vencido el término de retracto, únicamente será reembolsado el dinero pagado al momento
                                de efectuar la reserva en los siguientes eventos: (i) si le fuere negada al COMPRADOR la
                                aprobación de un crédito en caso de que se haya escogido ese medio de pago y dicho
                                crédito haya sido gestionado por WCAR; (ii) WCAR le notifique que el vehículo no se
                                encuentra disponible para la venta; y/o (ii) cuando sobrevengan circunstancias ajenas a
                                la voluntad de WCAR que imposibiliten efectuar la venta del vehículo.
                            </li>
                            <li>
                                WCAR efectuará la devolución del valor pagado por concepto de reserva, dentro de los dos
                                (2) días siguientes a la ocurrencia de cualquiera de estas circunstancias.{" "}
                            </li>
                            <li>
                                El vehículo que el COMPRADOR ha seleccionado ha sido previamente avaluado y peritado por
                                WCAR, quien ha sugerido el precio y demás condiciones de venta, las cuales a su vez han
                                sido aceptadas por su propietario y vendedor.
                            </li>
                        </ul>
                        <h3 className="mb-3">V. PROCESO DE COMPRAVENTA. </h3>
                        <ul>
                            <li>
                                El COMPRADOR pagará el precio descrito en las condiciones generales del contrato, más
                                los valores adicionales que le serán liquidados por concepto de: domicilio, impuestos,
                                traspaso, honorarios de trámites, multas pendientes por cancelar por parte de EL
                                COMPRADOR y alistamiento.
                            </li>
                            <li>
                                El precio deberá ser pagado mediante transferencia a la cuenta bancaria que indique WCAR
                                para tal fin, o a través de la pasarela de pagos disponible en la Plataforma Tecnológica
                                wcar.co.
                            </li>
                            <li>
                                Adicionalmente y sólo en caso de solicitarlo, se generarán cobros por concepto de
                                seguros, accesorios, garantía extendida o cualquier otro producto de valor agregado.
                            </li>
                            <li>
                                El COMPRADOR declara y acepta que WCAR no será responsable por la variación en el precio
                                indicado siempre que dicha variación sea atribuible a hechos ajenos a su control, como
                                lo son diferencias en los gastos de trámites, pago de impuestos o cualquier otro valor
                                adicional descrito en el literal A de la presente sección.
                            </li>
                            <li>
                                El COMPRADOR declara y reconoce que WCAR no radicará los documentos para el proceso de
                                traspaso hasta tanto no se encuentre acreditado el pago total del vehículo, incluido los
                                conceptos adicionales descritos en el literal A de la presente sección.{" "}
                            </li>
                        </ul>
                        <h3 className="mb-3">VI. CONDICIONES PARA EL PAGO DEL VEHICULO.</h3>
                        <ul>
                            <li>
                                El COMPRADOR pagará el precio descrito en las condiciones generales del contrato, más
                                los valores adicionales que le serán liquidados por concepto de: domicilio, impuestos,
                                traspaso, honorarios de trámites, multas pendientes por cancelar por parte de EL
                                COMPRADOR y alistamiento.
                            </li>
                            <li>
                                El precio deberá ser pagado mediante transferencia a la cuenta bancaria que indique WCAR
                                para tal fin, o a través de la pasarela de pagos disponible en la Plataforma Tecnológica
                                wcar.co.{" "}
                            </li>
                            <li>
                                Adicionalmente y sólo en caso de solicitarlo, se generarán cobros por concepto de
                                seguros, accesorios, garantía extendida o cualquier otro producto de valor agregado.{" "}
                            </li>
                            <li>
                                El COMPRADOR declara y acepta que WCAR no será responsable por la variación en el precio
                                indicado siempre que dicha variación sea atribuible a hechos ajenos a su control, como
                                lo son diferencias en los gastos de trámites, pago de impuestos o cualquier otro valor
                                adicional descrito en el literal A de la presente sección.
                            </li>
                            <li>
                                El COMPRADOR declara y reconoce que WCAR no radicará los documentos para el proceso de
                                traspaso hasta tanto no se encuentre acreditado el pago total del vehículo, incluido los
                                conceptos adicionales descritos en el literal A de la presente sección.{" "}
                            </li>
                        </ul>
                        <h3 className="mb-3">VII. CONDICIONES PARA LA ENTREGA DEL VEHICULO. </h3>
                        <ul>
                            <li>
                                El vehículo se entregará dentro de los dos (2) días hábiles siguientes a que se haya
                                emitido la tarjeta de propiedad a nombre del COMPRADOR siempre y cuando esté acreditado
                                el pago total del precio.
                            </li>
                            <li>
                                El vehículo se entregará en el lugar que acuerden las partes, diligencia en la cual se
                                comprometen a suscribir un Acta de entrega a conformidad del COMPRADOR.
                            </li>
                            <li>
                                Si EL COMPRADOR desea que el vehículo se entregue con la radicación del proceso de
                                traspaso, deberá firmar un pagaré por los posibles daños ocasionados al antiguo
                                propietario y deberá contar con un seguro contra todo riesgo activo.
                            </li>
                            <li>
                                EL COMPRADOR exonera de responsabilidad a WCAR por cualquier retraso para efectuar el
                                trámite de traspaso del vehículo, que sea atribuible a las autoridades de tránsito.{" "}
                            </li>
                        </ul>
                        <h3 className="mb-3">VIII. CLÁUSULA PENAL. </h3>
                        <ul>
                            <li>
                                En caso de incumplimiento del COMPRADOR, éste deberá pagar a WCAR la suma equivalente al
                                20% del precio de venta del vehículo, establecido en las condiciones generales del
                                contrato. En todo caso, el pago de la pena no extingue la obligación principal, la cual
                                puede ser exigida simultáneamente, pues la parte cumplida podrá demandar, o bien el
                                cumplimiento del contrato, o bien la resolución de este.
                            </li>
                        </ul>
                        <h3 className="mb-3">VIII. AUTORIZACIÓN CONSULTA Y REPORTE CENTRALES DE RIESGO. </h3>
                        <p>
                            Mediante la aceptación de los términos y condiciones aquí previstos, el COMPRADOR otorga el
                            consentimiento expreso e irrevocable a WCAR y/o a quien haga sus veces para: a) consultar,
                            en cualquier tiempo, en DataCrédito o en cualquier otra base de datos manejada por un
                            operador de información financiera y crediticia, toda la información relevante para conocer
                            su desempeño como deudor, su capacidad de pago, la viabilidad para entablar o mantener una
                            relación contractual, o para cualquier otra finalidad, incluyendo sin limitarse la
                            realización de campañas de mercadeo, ofrecimiento de productos y publicidad en general. b)
                            Reportar a DataCrédito, CIFIN o a cualquier otra base de datos manejada por un operador de
                            datos, tratados o sin tratar, sobre el cumplimiento o incumplimiento de sus obligaciones
                            crediticias, sus deberes legales de contenido patrimonial, sus datos de ubicación y contacto
                            (número de teléfono fijo, número de teléfono celular, dirección de domicilio, dirección
                            laboral y correo electrónico), así como otros atinentes a sus relaciones comerciales,
                            financieras y en general socioeconómicas que haya entregado o que consten en registros
                            públicos, base de datos públicas o documentos públicos.
                        </p>
                        <h3 className="mb-3">X. AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES. </h3>
                        <ul>
                            <li>
                                El COMPRADOR y/o Titular declara de manera voluntaria, previa, expresa, informada e
                                inequívoca autoriza a WCAR para tratar sus datos personales de acuerdo con la Política
                                de Tratamiento de Datos personales disponible en{" "}
                                <a href="https://wcar.co/politica-de-privacidad/" style={{ color: "orange" }}>
                                    https://wcar.co/politica-de-privacidad/
                                </a>
                                , la cual entró en vigencia desde 01 de diciembre de 2023 por un término indefinido.
                            </li>
                            <li>
                                El COMPRADOR y/o Titular declara de manera voluntaria, previa, expresa, informada e
                                inequívoca autoriza a WCAR para tratar sus datos personales de acuerdo con la Política
                                de Tratamiento de Datos personales disponible en{" "}
                                <a href="https://wcar.co/politica-de-privacidad/" style={{ color: "orange" }}>
                                    https://wcar.co/politica-de-privacidad/
                                </a>
                                , la cual entró en vigencia desde 01 de diciembre de 2023 por un término indefinido.
                            </li>
                            <li>
                                El COMPRADOR declara y acepta que para el desarrollo de las actividades aquí previstas
                                es posible que comparta datos personales que se encuentran en su poder con el VENDEDOR
                                y/o con las autoridades de tránsito, lo que implica una transmisión de datos. En todo
                                caso WCAR declara que cumple con las obligaciones y los parámetros determinados por la
                                ley 1581 de 2012 y sus normas concordantes para el tratamiento de datos de trabajadores,
                                proveedores y clientes, y se limitará a lo establecido en la política de protección de
                                datos.
                            </li>
                            <li>
                                EL COMPRADOR y/o Titular se reserva el derecho a suministrar a WCAR, de manera
                                facultativa, información sobre datos sensibles, así mismo está bajo su total discreción
                                el proporcionar cualquier dato personal sensible o no sensible que sea requerido por
                                WCAR. WCAR no solicitará bajo ninguna circunstancia el suministro de datos sensibles a
                                los Titulares.
                            </li>
                            <li>
                                Mediante esta autorización, WCAR queda autorizada para recolectar, compilar almacenar,
                                circular, compartir con terceros si es necesario, transferir y transmitir en el
                                territorio nacional o internacionalmente, usar, comunicar, procesar, organizar,
                                analizar, estudiar, clasificar, suprimir y disponer de los datos personales del Titular
                                de acuerdo con las finalidades previstas en esta autorización y la presente Política. En
                                cualquier caso, el (los) Encargado (s) del Tratamiento, si lo hubiere se someterán a las
                                presentes Políticas de Tratamiento de Datos personales y a los manuales internos de
                                procedimiento para el Tratamiento de la información.
                            </li>
                            <li>
                                Cualquier consulta, petición, solicitud, o queja por parte del Titular respecto del
                                tratamiento de sus datos personales, así como rectificaciones, actualizaciones, o
                                supresión de sus Datos Personales del Tratamiento por parte de WCAR, podrá ser tramitada
                                a través del departamento de Servicio Al Cliente de WCAR, al correo electrónico
                                contacto@wcar.co o directamente en la Calle 98A #69B-35 en la ciudad de Bogotá,
                                Colombia. Toda consulta será respondida dentro de los 10 días hábiles siguientes a la
                                recepción de la misma.
                            </li>
                        </ul>
                        <h3 className="mb-3">XI. DECLARACIÓN DE ORÍGEN DE FONDOS. </h3>
                        <ul>
                            <li>
                                El COMPRADOR declara que los fondos y/o recursos que ha destinado o destinará para la
                                adquisición de bienes y/o servicios a través de WCAR provienen de fuentes legítimas y
                                lícitas, y no están vinculados a actividades ilícitas tales como lavado de activos o
                                financiación del terrorismo, conforme a las leyes de la República de Colombia.
                            </li>
                            <li>
                                El COMPRADOR se compromete a brindar, de ser necesario, documentación y soportes que
                                demuestren la legalidad y origen de los fondos utilizados en las transacciones de pago
                                efectuadas con WCAR, de acuerdo con la Ley 526 de 1999, y demás normas que regulan el
                                Sistema de Prevención y Control de Lavado de Activos y Financiación del Terrorismo en
                                Colombia.
                            </li>
                            <li>
                                El COMPRADOR declara que será el único responsable y exonera a WCAR respecto de
                                cualquier responsabilidad en relación con la legalidad y origen de los fondos utilizados
                                en las transacciones de pago. En caso de cualquier investigación, reclamo, o proceso
                                legal relacionado con el origen de los fondos, el COMPRADOR se compromete a asumir la
                                defensa y cualquier responsabilidad, liberando a WCAR de cualquier implicación y/o
                                consecuencia.
                            </li>
                            <li>
                                El COMPRADOR reconoce que WCAR se reserva el derecho de rechazar o cancelar
                                transacciones si hay sospechas sobre la legalidad del origen de los fondos o si se
                                incumplen las regulaciones aplicables en la República de Colombia.
                            </li>
                            <li>
                                El COMPRADOR reconoce que la falsedad e inexactitud en esta declaración puede resultar
                                en consecuencias legales conforme a las leyes colombianas y la terminación de la
                                relación comercial con WCAR.
                            </li>
                        </ul>
                        <h3 className="mb-3">XII. NOTIFICACIONES. </h3>
                        <ul>
                            <li>
                                Todas las comunicaciones o notificaciones dirigidas a WCAR con ocasión de este contrato,
                                ya sean físicas o electrónicas, se enviarán a las siguientes direcciones Dirección:
                                Calle 98 A # 69b-35 en Bogotá - Colombia. Email: contacto@wcar.co Teléfono: 3244001212.
                                El COMPRADOR reconoce y acepta que será notificado según la información establecida en
                                las condiciones generales del Contrato.
                            </li>
                        </ul>
                        <h3 className="mb-3">XIII. LEY APLICABLE Y RESOLUCIÓN DE CONTROVERSIAS. </h3>
                        <ul>
                            <li>
                                Aplicará al presente Contrato, las leyes de la República de Colombia. Las diferencias
                                que surjan entre las partes por razón o con ocasión del presente contrato, se procurarán
                                resolver mediante procedimientos de autocomposición, tales como transacción, amigable
                                composición o conciliación, a elección de la Parte que active esta cláusula. En caso de
                                fracasar el mecanismo de arreglo directo dentro del término acordado, las diferencias
                                serán resueltas por la jurisdicción ordinaria de los jueces o tribunales de Colombia.
                            </li>
                        </ul>

                        {/*  */}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BuyerPolicies;
