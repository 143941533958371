import "./applicationFormStyles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import { sendMessage } from "../../../utils/sendMessage";
import { useEffect, useState } from "react";
import { Spiner } from "../../components/spiner/spiner";
import { ref, onValue, set, push } from "firebase/database";
import "firebase/database";
import { dataBase } from "../../../utils/notification";

interface Room {
    room: String;
    advisor_status: boolean;
    user_status: boolean;
}

export const ApplicationForm = ({ setOpenForm, setOpenModal, setAlert }) => {
    const Navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const url = "https://api.wcar.online/api";
    const [rooms, setRooms] = useState({});
    const [loading, setLoading] = useState(false);
    const [clientData, setClientData] = useState(null);
    const [topic, setTopic] = useState(null);

    useEffect(() => {
        onValue(ref(dataBase, "video_assistance"), (snapshot) => {
            setRooms(snapshot.val());
        });
    }, []);

    useEffect(() => {
        if (clientData && topic) {
            const newKey = Object.entries(rooms)
                .filter(([key, value]: [string, Room]) => value.room === topic)
                .map(([key, value]) => key);
            rooms[newKey[0]]?.user_state &&
                setTimeout(() => {
                    Navigate(routes.Videoasistencia.relativePath, {
                        state: { topic: topic, name: clientData.name, phone: clientData.phone },
                    });
                }, 100);
        }
    }, [rooms]);

    const onSubmit = handleSubmit(async (data) => {
        push(ref(dataBase, "video_assistance_register"), data);
        setLoading(true);

        try {
            const res = await fetch(`${url}/video-assistances/create/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const resJson = await res.json();

            setClientData({ name: `${resJson.name} ${resJson.last_name}`, phone: resJson.phone });

            getAvisor(resJson);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    });

    const getAvisor = async (data: any) => {
        try {
            const res = await fetch(`${url}/avisors/`);
            const resJson = await res.json();

            const message = `El cliente: ${data.name} ${data.last_name}, con el número de teléfono: ${data.phoneClient}, y el correo: ${data.email} quiere entrar a una video asistencia.\n\nMotivo: ${data.type_contact}`;

            // const promises = resJson.map(async (advisor: any) => {
            //     if (data.type_contact === (advisor.buy_or_sell ? "compra" : "venta")) {
            //         sendMessage(message, advisor.phone);
            //     }
            // });

            // await Promise.all(promises);

            connect(data.type_contact);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const connect = async (typeContact: string) => {
        try {
            const res = await fetch(`${url}/video-assistances-rooms/connect/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ role: 0, type_contact: typeContact }),
            });
            const resJson = await res.json();

            setTopic(resJson?.room?.name_room);

            const newKey = Object.entries(rooms)
                .filter(([key, value]: [string, Room]) => value.room === resJson?.room?.name_room)
                .map(([key, value]) => key);

            set(ref(dataBase, "video_assistance/" + newKey[0]), {
                advisor_status: true,
                type: rooms[newKey[0]].type,
                user_state: rooms[newKey[0]]?.user_state,
                room: rooms[newKey[0]]?.room,
            });

            enterTheMeeting(resJson.room.id);
        } catch (error) {
            setOpenForm(false);
            setAlert(true);
            setLoading(false);
            console.error(error);
        }
    };

    const enterTheMeeting = async (id: number) => {
        try {
            const res = await fetch(`${url}/video-assistances-rooms/${id}/update/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ quantity_persons: 1 }),
            });

            // lostCall(res.url);
        } catch (error) {
            console.error(error);
        }
    };

    const lostCall = (url: string) => {
        const newKey = Object.entries(rooms)
            .filter(([key, value]: [string, Room]) => value.room === topic)
            .map(([key, value]) => key);

        try {
            const timer = setTimeout(async () => {
                const res = await fetch(`${url}`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ quantity_persons: 1 }),
                });

                set(ref(dataBase, "video_assistance/" + newKey[0]), {
                    advisor_status: false,
                    type: rooms[newKey[0]]?.type,
                    user_state: rooms[newKey[0]]?.user_state,
                    room: rooms[newKey[0]]?.room,
                });

                setOpenForm(false);
                setAlert(true);
                setLoading(false);
            }, 30000);

            rooms[newKey[0]]?.user_state && clearTimeout(timer);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {loading && <Spiner />}
            <div className="application_form text-white">
                <form className="form" onSubmit={onSubmit}>
                    <p className="title">Formulario de solicitud</p>
                    <div className="input_container form-group">
                        <label htmlFor="name" className="label_input mandatory">
                            Nombre
                        </label>
                        <input
                            type="text"
                            id="name"
                            placeholder="nombre"
                            className="input form-control"
                            {...register("name", { required: true })}
                        />
                    </div>
                    <div className="input_container form-group">
                        <label
                            htmlFor="
                        "
                            className="label_input mandatory"
                        >
                            Apellido
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            placeholder="apellido"
                            className="input form-control"
                            {...register("last_name", { required: true })}
                        />
                    </div>

                    <div className="input_container form-group">
                        <label
                            htmlFor="
                        "
                            className="label_input mandatory"
                        >
                            Que deseas hacer?
                        </label>
                        <select
                            className="form-control input select_form"
                            defaultValue={""}
                            {...register("type_contact", { required: true })}
                        >
                            <option value="compra" selected>
                                Comprar un vehículo
                            </option>
                            <option value="venta">Vender un vehículo</option>
                        </select>
                    </div>

                    <div className="input_container form-group">
                        <label htmlFor="phone" className="label_input mandatory">
                            Teléfono
                        </label>
                        <input
                            type="number"
                            id="phone"
                            placeholder="número de teléfono"
                            className="input form-control"
                            {...register("phone", { required: true, pattern: /^[0-9]+$/ })}
                        />
                    </div>
                    <div className="input_container form-group">
                        <label htmlFor="email" className="label_input mandatory">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            placeholder="ejemplo@gmail.com"
                            className="input form-control"
                            {...register("email", { required: true })}
                        />
                    </div>
                    <div className="botton">
                        <button
                            className="btn btn_orange_transparent"
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenForm(false);
                                setOpenModal(true);
                            }}
                        >
                            CANCELAR
                        </button>
                        <button className="ms-3 btn btn_orange">CONTINUAR</button>
                    </div>
                </form>
            </div>
        </>
    );
};

// const createRoom = () => {
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWJgRePxhyjZbvJo",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uAePKfQtLsHyiGzwRv",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_XyNvUhDsFqGeWbRp",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_kIjVdYsErFtGuHbN",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWJfsdfWZbRWsf",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWrasdwta",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWJgdSrqawrR",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "buy",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWkjhaAEDf",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWJsdfjsaFSsDff",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uyEWJKsfdjesa",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKJkhfsQrjlDa",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKJkhKaASD",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKJAdmQkdaDfg",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKJASDjmSfLKgag",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKsjkSjmKgaGFg",
//     });
//     push(ref(dataBase, "video_assistance"), {
//         type: "sell",
//         advisor_status: false,
//         user_state: false,
//         room: "room_uKsjJadnqShFurg",
//     });
// };
