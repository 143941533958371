import "./HomeStyles.scss";
import React, { FC, useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import CardServiceComponent from "./components/cardService/CardServiceComponent";
import SliderComponent from "../../components/slider/SliderComponent";
import Layout from "../../layout/Layout";
import Icons from "../../assets/Icons";
import AlliesLineComponent from "../../components/alliesLine/AlliesLineComponent";
import { routes } from "../../routes/RoutesComponent";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Carousel1 } from "../../components/carousel/carousel";
import { Financing } from "../../components/financing/financing";
import Button from "../../components/Button";
import "react-multi-carousel/lib/styles.css";
import di from "../../../../di/DependencyInjection";
import GetSomeRandomCarsUseCase, {
    GetSomeRandomCarsUseCaseName,
} from "../../../../domain/use_cases/car/GetSomeRandomCarsUseCase";
import CarEntity from "../../../../domain/entities/CarEntity";
import OrangeFestModal from "../../components/OrangeFestModal/OrangeFestModal";

const HomePage: FC<{}> = () => {
    // const [showOrangeBanner, setShowOrangeBanner] = React.useState<boolean>(
    //     !window.localStorage.getItem("orangeFestModal") ? false : true
    // );
    const [showOrangeBanner, setShowOrangeBanner] = React.useState<boolean>(true);
    const [carsInfo, setCarsInfo] = useState([]);
    const [cars, setCars] = useState<CarEntity[] | undefined>(undefined);

    useEffect(() => {
        fetch(`https://api.wcar.online/api/cars/carousel`)
            .then((res) => res.json())
            .then((res) => setCarsInfo(res))
            .catch((e) => console.error(e));
    }, []);

    const _getSomeCars = async () => {
        try {
            const response = await di.get<GetSomeRandomCarsUseCase>(GetSomeRandomCarsUseCaseName).call();
            setCars(response);
        } catch (error) {
            setCars([]);
        }
    };

    useEffect(() => {
        _getSomeCars();
    }, []);

    return (
        <Layout>
            <Helmet>
                <title>wcar #1 Más que vender te aconsejamos</title>
                <meta
                    name="description"
                    content="WCAR - tu mejor opción para comprar y vender carros usados! Conocemos el mercado y te asesoramos para que encuentres el auto perfecto. ¡Contáctanos ahora!"
                />
                <meta
                    name="keywords"
                    content="wcar, transparencia brutal, vehículos en venta, compras tu auto online, enviamos tu auto a tu casa, peritaje gratis"
                />
                <link rel="canonical" href="https://wcar.co" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="home_page">
                <section className="first_section position-relative d-md-block d-flex flex-column-reverse">
                    <div className="position-sm-absolute w-100 d-flex justify-content-center img_car_01_container">
                        <img
                            src="/assets/home/car_01.webp"
                            alt="Comprar con wcar"
                            title="Comprar con wcar"
                            className="img_car_01"
                            width={100}
                            height={300}
                        />
                    </div>
                    <div className="container py-5">
                        <div className="row">
                            <div className="col-12 col-sm-7">
                                <div className="d-flex flex-column">
                                    <h1 className="side side_top wcar-absolute">wcar</h1>
                                    <h2 className="mt-3 text-header">
                                        <span className="text-header-normalized">Somos la</span> única plataforma <br />
                                        <span className="text-header-normalized">que</span>{" "}
                                        <span className="text-header-normalized">vende</span> autos usados <br /> con{" "}
                                        <span
                                            className="my-3 text_orange text_italic title_orange text-header-italic"
                                            style={{
                                                fontWeight: "500",
                                            }}
                                        >
                                            peritaje online
                                        </span>
                                    </h2>
                                    <Link to={routes.buyYourCar.relativePath} className="mt-4">
                                        <Button
                                            variant="primary"
                                            iconRight={<Icons.ArrowCircle width={32} height={32} />}
                                            label="Compra tu carro"
                                            withOutBorder
                                        />
                                    </Link>
                                    <Link to={routes.sellYourCar.relativePath} className="my-4">
                                        <Button
                                            iconRight={<Icons.ArrowCircle width={32} height={32} />}
                                            label="VENDE TU CARRO"
                                            variant="secondary"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="second_section col-md-12 container">
                    <div className="row">
                        <div className="car_02 col-md-3 position-relative">
                            <div className="wcar_img_2 position-absolute col-md-12"></div>
                        </div>
                        <div className="col-md-9 mt-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="sub_title_1 text_black text-center m-0 text-md-start">
                                        Transparencia <i className="text_orange">brutal.</i>
                                    </h2>
                                    <br />
                                    <SliderComponent>
                                        <div className="col-md-12 my-3">
                                            <CardServiceComponent
                                                title="No omitimos ningún detalle"
                                                image="/assets/icons/car.svg"
                                                description="Te contamos todo lo relacionado con tu auto tal y como es."
                                            />
                                        </div>
                                        <div className="col-md-12 my-3">
                                            <CardServiceComponent
                                                title="Peritaje gratis disponible online"
                                                image="/assets/icons/star.svg"
                                                description="Publicamos nuestros peritajes para que puedas obtener toda la información antes de comprar."
                                            />
                                        </div>
                                        <div className="col-md-12 my-3">
                                            <CardServiceComponent
                                                title="Garantía por seis meses"
                                                image="/assets/icons/computer.svg"
                                                description="Te cubre más de 3000 piezas y la puedes extender hasta por 2 años."
                                            />
                                        </div>
                                        <div className="col-md-12 my-3">
                                            <CardServiceComponent
                                                title="Precio técnicamente calculado"
                                                image="/assets/icons/money.svg"
                                                description="No especulamos. A través de nuestro AI Wcalculator llegamos a  precios de venta reales de mercado."
                                            />
                                        </div>
                                        <div className="col-md-12 my-3">
                                            <CardServiceComponent
                                                title="Tecnología para brindar servicio personalizado."
                                                image="/assets/icons/person.svg"
                                                description="La tecnología es para nosotros un camino para mejorar el servicio, no para reemplazar las interacciones."
                                            />
                                        </div>
                                    </SliderComponent>
                                </div>
                                <div className="d-flex justify-content-end col-md-12 mt-4 mb-3">
                                    <Financing bgColor="#00fefe" textColor="#000" />
                                </div>
                                <div
                                    className="mt-4 pb-5 w-100 d-flex position-relative"
                                    style={{ background: "#F6F7F9" }}
                                >
                                    <div className="yellow_square_2 position-absolute d-block"></div>
                                    <div className="blue_square position-absolute d-block"></div>
                                    <div className="d-none d-md-block col-md-3 me-5 position-relative">
                                        <img
                                            src="./assets/home/car_04.svg"
                                            alt="wcar"
                                            className="car_4 position-absolute d-none d-md-block"
                                            loading="lazy"
                                            decoding="async"
                                        />
                                        <div className="yellow_square position-absolute d-none d-md-block"></div>
                                        <div className="car_3 position-absolute d-none d-md-block"></div>
                                        <div className="lines_3 position-absolute d-none d-sm-block" />
                                    </div>
                                    <div className="col-md-8 row pb-5 pb-md-0">
                                        <h2 className="sub_title_2 text_black mt-5 mb-4 mx-0 text-center">
                                            Compras online con atención <i className="text_orange">personalizada.</i>
                                        </h2>
                                        <div className="col-md-6 my-3">
                                            <CardServiceComponent
                                                title="Compras tu auto online."
                                                image="/assets/home/shopping_car.svg"
                                                description="Puedes reservarlo o pagarlo totalmente a través de nuestra plataforma."
                                            />
                                        </div>
                                        <div className="col-md-6 my-3">
                                            <CardServiceComponent
                                                title="Tecnología para acercar las personas."
                                                image="/assets/home/mause.svg"
                                                description="Obtén atención personalizada a través de WCAR para resolver cualquier duda."
                                            />
                                        </div>
                                        <div className="col-md-6 my-3">
                                            <CardServiceComponent
                                                title="Enviamos tu auto a tu casa."
                                                image="/assets/home/hause.svg"
                                                description="Compra a través de nuestra plataforma y enviamos el auto a la comodidad de tu hogar."
                                            />
                                        </div>
                                        <div className="col-md-6 my-3">
                                            <CardServiceComponent
                                                title="Financiación en unos cuantos clicks."
                                                image="/assets/home/icon_dolar.svg"
                                                description="Aplica para un crédito en minutos desde nuestra web."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end px-0 pe-md-0 ">
                                    <div className="us_wcar position-relative text-white px-5 py-5">
                                        <img
                                            src="./assets/recs/lines_zigs.png"
                                            alt="wcar"
                                            title="wcar"
                                            style={{ position: "absolute", right: "10%", top: "2vw" }}
                                            loading="lazy"
                                            decoding="async"
                                        />
                                        <img
                                            src="./assets/home/lines_2.svg"
                                            alt="wcar"
                                            title="wcar"
                                            style={{ position: "absolute", right: 0, bottom: 0, zIndex: "0" }}
                                            loading="lazy"
                                            decoding="async"
                                        />
                                        <div className="d-flex align-items-center pb-1">
                                            <div
                                                className="me-2"
                                                style={{
                                                    width: "48px",
                                                    height: "1px",
                                                    background: "#00FEFE",
                                                }}
                                            ></div>
                                            <div>Nosotros</div>
                                        </div>
                                        <h3 className="h3 pb-2">
                                            <b>¿Qué es wcar?</b> <br />
                                        </h3>
                                        <div className="w-75">
                                            <p>
                                                Somos un one Stop Marketplace apalancado en AI. Nuestro compromiso es
                                                brindar información brutalmente transparente y un servicio altamente
                                                personalizado. Acompañamos la evolución del usuario en cada paso.
                                            </p>
                                        </div>
                                        <br />
                                        <div
                                            className="d-flex flex-column flex-md-row flex-wrap"
                                            style={{ zIndex: "9" }}
                                        >
                                            <Link to={routes.buyYourCar.relativePath}>
                                                <Button
                                                    label="COMPRA TU CARRO"
                                                    iconRight={<Icons.ArrowCircle width={32} />}
                                                    className="my-2"
                                                    variant="primary"
                                                />
                                            </Link>

                                            <Link to={routes.sellYourCar.relativePath}>
                                                <Button
                                                    className="my-2 ms-lg-3"
                                                    label="VENDE TU CARRO"
                                                    variant="tertiary"
                                                    iconRight={<Icons.ArrowCircle width={32} />}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub_title_3 side side_top d-flex mt-4 align-items-center">
                                    <h2>
                                        Vehículos <i>en venta</i>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="third_section pt-4 pb-5">
                    <div
                        className="container w-100 d-flex flex-column justify-content-evenly"
                        style={{ height: "530px" }}
                    >
                        <Carousel1 cars={carsInfo} />
                    </div>
                </section>
                <section className="quater_section from_left_3 container">
                    <AlliesLineComponent />
                </section>
            </div>
            {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
        </Layout>
    );
};

export default HomePage;
