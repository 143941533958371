import { getApp, getApps, initializeApp } from "firebase/app";
import { confirmPasswordReset, getAuth } from "firebase/auth";
import { getFirebaseConfig } from "./FirebaseConfig";

// const app = getApps().length === 0 ? initializeApp(getFirebaseConfig()) : getApp();
const app = initializeApp(getFirebaseConfig(), "app-mobile-wcar");
const auth = getAuth(app);

export const confirmThePasswordReset = async (oobCode: string, newPassword: string) => {
    console.log(getFirebaseConfig());
    if (!oobCode || !newPassword) return;

    return await confirmPasswordReset(auth, oobCode, newPassword);
};
