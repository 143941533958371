import "./cardFinancingStyles.scss";

export const CardFinancingBanner = ({ setShowGarantieModal, setSrvCode }) => {
    return (
        <>
            <div className="card_financing_banner bg-black w-100">
                <div className="container d-flex">
                    <div className="info_card">
                        <div className="title_container">
                            <p className="sub_title_2 mt-5  mx-0 er text-light">
                                {/* <p className="title"> */}
                                Financia tu seguro en unos
                                <br />
                                <i>cuantos clics</i>
                            </p>
                        </div>
                        <div className="content_container">
                            <div className="item_list ">
                                <img src="./assets/icons/check-white.png" alt="wcar" />
                                <p className={"fs-5"}>Elige el monto que mejor se adapta.</p>
                            </div>
                            <div className="item_list mt-2">
                                <img src="./assets/icons/check-white.png" alt="wcar" />
                                <p className={"fs-5"}>Llévalo con unos pocos clics</p>
                            </div>
                            <div className="item_list mt-2">
                                <img src="./assets/icons/check-white.png" alt="wcar" />
                                <p className={"fs-5"}>Fináncialo hasta en 18 cuotas</p>
                            </div>
                            <div className="item_list mt-2">
                                <img src="./assets/icons/check-white.png" alt="wcar" />
                                <p className={"fs-5"}>
                                    Asegura tu vehículo contra daños por accidentes de tránsito, hurtos del carro, daños
                                    ocasionados a bienes de terceros y el fallecimiento o posibles lesiones a terceros.
                                </p>
                            </div>
                        </div>
                        <div className="button_container">
                            <button
                                className="btn-custom"
                                onClick={() => {
                                    setShowGarantieModal(true);
                                    setSrvCode("1002");
                                }}
                            >
                                Comprar seguro
                            </button>
                        </div>
                    </div>
                    <div className="img_card">
                        <img
                            src="./assets/img-banner.png"
                            alt="wcar"
                            className="h-100"
                            style={{ width: "650px", objectFit: "cover" }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
