import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./NavbarStyles.scss"; // Custom CSS file for Navbar
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import { Collapse, Navbar, Nav, NavItem, NavLink } from "reactstrap";
import UserContext from "../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../domain/providers/user/UserContextType";
import di from "../../../../di/DependencyInjection";
import SignOutUseCase, { SignOutUseCaseName } from "../../../../domain/use_cases/auth/SignOutUseCase";
import TypeVehicleContext from "../../../../domain/providers/typeVehicle/TypeVehicleContext";
import TypeVehicleContextType from "../../../../domain/providers/typeVehicle/TypeVehicleContextType";
import AccountCircleIcon from "../../assets/AccountCircleIcon";
import { IoIosArrowDown } from "react-icons/io";

const NavbarComponent = () => {
    const { user } = useContext(UserContext) as UserContextType;
    const { typeVehicles } = useContext(TypeVehicleContext) as TypeVehicleContextType;
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const targetDate = new Date("2024-07-27T00:00:00");
        const interval = setInterval(() => {
            const now = new Date();
            // @ts-ignore
            const difference = targetDate - now;

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeLeft({ days, hours, minutes, seconds });

            if (difference < 0) {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    const _handleAccountButton = async () => {
        if (user == null) {
            navigate(routes.signIn.relativePath);
        } else {
            await di.get<SignOutUseCase>(SignOutUseCaseName).call();
            navigate(routes.home.relativePath);
        }
    };

    return (
        <>
            <Navbar className="bg-transparent px-3 w-100 z-2" expand="lg">
                <Link className="navbar-brand me-5" to={routes.home.relativePath}>
                    <img src="/assets/logos/horizontal.svg" title="wcar" alt="wcar" height="50" loading="lazy" />
                    {/* Add the logo image */}
                </Link>

                <Collapse navbar id="navbarNav" isOpen={isOpen}>
                    <Nav className="ml-auto d-md-flex align-items-center" navbar>
                        <NavItem className="mx-3">
                            <Link className="nav-link" style={{ fontWeight: 500 }} to={routes.aboutUs.relativePath}>
                                Sobre Nosotros
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3">
                            <div className="dropdown">
                                <div className="dropdown-title d-flex align-items-center">
                                    <Link
                                        to={routes.buyOrSell.relativePath}
                                        style={{ fontWeight: 500 }}
                                        className="nav-link d-flex align-items-center"
                                    >
                                        Compra o Vende
                                        <span className="text_reduced ms-1">
                                            <IoIosArrowDown size={18} />
                                        </span>
                                    </Link>
                                </div>
                                <div className="dropdown_content">
                                    <div className="dropdown">
                                        <div className="dropdown-title d-flex align-items-center">
                                            <Link
                                                to={routes.buyYourCar.relativePath}
                                                className="nav-link d-flex align-items-center"
                                            >
                                                Compra tu carro
                                                <span className="ms-1">
                                                    <IoIosArrowDown size={18} />
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="dropdown_content right">
                                            {typeVehicles.map((typeVehicle, index) => (
                                                <Link
                                                    key={index}
                                                    to={
                                                        parseInt(typeVehicle.id) === 3
                                                            ? "/compra-tu-carro/carros-sedan-usados" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 4
                                                            ? "/compra-tu-carro/carros-híbridos-colombia" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 6
                                                            ? "/compra-tu-carro/hatchback-colombia" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 8
                                                            ? "/compra-tu-carro/camionetas-usadas" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : routes.buyYourCar.relativePath +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                    }
                                                    className="dropdown-item"
                                                >
                                                    <img
                                                        src={typeVehicle.photo}
                                                        alt={typeVehicle.name}
                                                        title={typeVehicle.name}
                                                        className="me-2"
                                                        width={24}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                    {typeVehicle.name}
                                                    <span className="text_gray">({typeVehicle.count ?? 0})</span>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    <Link to={routes.sellYourCar.relativePath} className="nav-link">
                                        Vende tu carro
                                    </Link>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="mx-3">
                            <div className="dropdown">
                                <div className="dropdown-title">
                                    <div className="nav-link d-flex align-items-center" style={{ fontWeight: 500 }}>
                                        Servicios
                                        <span className="ms-1">
                                            <IoIosArrowDown size={18} />
                                        </span>
                                    </div>
                                </div>
                                <div className="dropdown_content">
                                    <Link to={routes.services.relativePath} className="dropdown-item">
                                        Financiación
                                    </Link>
                                    <Link to={routes.insurance.relativePath} className="dropdown-item">
                                        Seguros
                                    </Link>
                                    <Link to={routes.procedures.relativePath} className="dropdown-item">
                                        Trámites
                                    </Link>
                                    <Link to={routes.workshopAndService.relativePath} className="dropdown-item">
                                        Taller
                                    </Link>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="mx-3">
                            <Link className="nav-link" to={routes.blog.relativePath} style={{ fontWeight: 500 }}>
                                Blog
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3">
                            <NavLink href={routes.contact.relativePath} style={{ fontWeight: 500 }}>
                                Contacto
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>

                <div className="d-flex navbar_icons">
                    <div className="btn d-flex align-items-center btn-account" onClick={_handleAccountButton}>
                        <AccountCircleIcon size={33} />
                        <div className="d-none d-xl-block" style={{ marginLeft: "20px" }}>
                            {user?.name ?? "Cuenta"}
                        </div>
                    </div>
                    <button
                        className="navbar-toggler border-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </Navbar>
        </>
    );
};

export default NavbarComponent;
