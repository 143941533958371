import "./successfulModalStyles.scss"


export const SuccessfulModal = ({ setSuccessModal }) => {

    return (
        <>
            <div className="success_modal_container">
                <div className="success_modal position-relative">
                    <div className="d-flex justify-content-center">
                        <img src="/assets/icons_contact/check_true.svg" alt="wcar" className="check_img" />
                    </div>
                    <div className="position-absolute fs-5 text-black fw-semibold" style={{ cursor: "pointer", right: "5%", top: "10%" }} onClick={() => setSuccessModal(false)}>X</div>
                    <p className="fs-5 text-center text-black fw-semibold mt-3">Gracias por tu información. En minutos te estaremos contactando por llamada o por whatsapp.</p>
                </div>
            </div>
        </>
    )
}