export function WarrantyStar() {
    return (
        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.4524 11.7414L5.40495 11.8918L5.53039 11.7962L8.00006 9.91413L10.4697 11.7962L10.5958 11.8923L10.5477 11.7412L9.57551 8.69156L12.0391 6.95756L12.1682 6.86667H12.0103H9.00256L8.04792 3.71119L8.00032 3.55384L7.95225 3.71105L6.98743 6.86667H3.97957H3.82166L3.95079 6.95756L6.41446 8.69162L5.4524 11.7414ZM5.52472 13.8805C6.29984 14.2158 7.1255 14.3833 8.00118 14.3833C8.88365 14.3833 9.71361 14.2158 10.4905 13.8803C11.2666 13.5453 11.9422 13.0876 12.5168 12.5072C13.0912 11.9269 13.5458 11.2495 13.8806 10.4754C14.2159 9.70023 14.3834 8.87457 14.3834 7.99889C14.3834 7.11642 14.2158 6.28646 13.8804 5.50952C13.5454 4.73354 13.0905 4.05804 12.5157 3.48349C11.9409 2.90895 11.2651 2.45428 10.4888 2.11944L10.4691 2.16506L10.4888 2.11944C9.71154 1.78418 8.88141 1.61669 7.99895 1.61669C7.12327 1.61669 6.29778 1.78428 5.52298 2.11969C4.74919 2.45466 4.07208 2.90949 3.49206 3.48417C2.9119 4.05898 2.45439 4.73483 2.1195 5.51125C1.78424 6.28853 1.61675 7.11866 1.61675 8.00112C1.61675 8.8768 1.78434 9.70229 2.11975 10.4771C2.45477 11.251 2.91244 11.9281 3.49273 12.5082C4.07302 13.0882 4.75048 13.5457 5.52472 13.8805ZM11.9172 11.9121C10.8438 12.9889 9.53909 13.5269 8.00006 13.5269C6.46794 13.5269 5.16487 12.989 4.08799 11.9121C3.0111 10.8352 2.47315 9.53213 2.47315 8C2.47315 6.46098 3.01115 5.15625 4.08793 4.08285C5.1648 3.00937 6.46789 2.47309 8.00006 2.47309C9.53914 2.47309 10.8439 3.00941 11.9173 4.08279C12.9907 5.15619 13.527 6.46093 13.527 8C13.527 9.53218 12.9907 10.8353 11.9172 11.9121Z"
                fill="#189D0E"
                stroke="#189D0E"
                stroke-width="0.1"
            />
        </svg>
    );
}
