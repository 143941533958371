import Button from "../../../../../components/Button";
import "./cardStyles.scss";

export const Card = ({ cardInfo, setShowGarantieModal, setSrvCode }) => {
    return (
        <div className="w-100" style={{ background: cardInfo.cardColor }}>
            <div
                className="card_container container"
                style={{
                    flexDirection: cardInfo.directionReverse ? "row-reverse" : "row",
                    color: cardInfo.textColor,
                    width: cardInfo.cardWidth,
                }}
            >
                <img
                    src="./assets/home/car_05.svg"
                    alt="wcar"
                    title="wcar"
                    className="svg_wcar"
                    style={{ right: !cardInfo.directionReverse && "0px", left: cardInfo.directionReverse && "0px" }}
                />

                <div
                    className=" card_part_one sub_title_2 mt-5 mx-0 align-items-center justify-content-center"
                    style={{ display: cardInfo?.flex ? "flex" : "block" }}
                >
                    <h2 className="text_italic text-white">
                        {cardInfo.titleBlack} <i>{cardInfo.titleOrange}</i>
                    </h2>
                    {cardInfo?.paragraphOne && (
                        <div className="item_list">
                            <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                            <p className={"fs-5"}>{cardInfo.paragraphOne}</p>
                        </div>
                    )}
                    {cardInfo?.paragraphTwo && (
                        <div className="item_list mt-2">
                            <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                            <p className={"fs-5"}>{cardInfo.paragraphTwo}</p>
                        </div>
                    )}
                    {cardInfo?.paragraphThree && (
                        <div className="item_list mt-2">
                            <img src="./assets/icons/check-white.png" className={"mt-2"} alt="wcar" />
                            <p className={"fs-5"}>{cardInfo.paragraphThree}</p>
                        </div>
                    )}
                    {cardInfo?.buttonText && (
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setShowGarantieModal(true);
                                setSrvCode("1003");
                            }}
                            label={cardInfo.buttonText}
                        ></Button>
                    )}
                </div>
                {/* <div className="div_card_phone_img2">
                    <img src={cardInfo.imgUrl} alt="wcar" className="card_phone_img2" />
                </div> */}

                <div className="card_part_two">
                    <img src={cardInfo.imgUrl} alt="wcar" className="card_phone_img" />
                </div>
            </div>
        </div>
    );
};
