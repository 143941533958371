const config = {
    apiKey: "AIzaSyDEx-zs4kii4zK01qfpyYU9SswlxMM2yDc",
    authDomain: "app-mobile-wcar.firebaseapp.com",
    projectId: "app-mobile-wcar",
    storageBucket: "app-mobile-wcar.appspot.com",
    messagingSenderId: "1047821664434",
    appId: "1:1047821664434:web:64f2c2cfcd93203b56c9ab",
    measurementId: "G-CDKL4EEVY6",
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error(
            "No Firebase configuration object provided." +
                "\n" +
                "Add your web app's configuration object to firebase-config.ts"
        );
    } else {
        return config;
    }
}
