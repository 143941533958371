import "./BuyYourCarModalFormStyles.scss";
import Button from "../../../../../components/Button";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";

export const BuyYourCarModalForm = ({ setBuyYourCarModalForm, setSuccessModal }) => {
    const { register, handleSubmit, watch } = useForm();
    const [watchCity, watchName, watchPhone] = watch(["city", "name", "phone"]);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (watchCity && watchName && watchPhone) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [watchCity, watchName, watchPhone]);

    const onSubmit = handleSubmit(async (data) => {
        localStorage.setItem("easy_buy", JSON.stringify(data));
        try {
            const res = await fetch("https://api.wcar.online/api/easy-buy/create/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            setBuyYourCarModalForm(false);
            setSuccessModal(true);
        } catch (error) {
            console.log(error);
        }
    });

    // http://localhost:3001/compra-tu-carro/sedan/nissan-sentra-2.0-sl-at/374

    return (
        <>
            <div className="buy_your_car_modal_container">
                <div className="buy_your_car_modal">
                    <form className="position-relative" onSubmit={onSubmit}>
                        <div className="mt-2">
                            <h4 className="text-center">
                                Compra tu carro <span style={{ fontWeight: 900, color: "#000" }}>fácil y rápido</span>
                            </h4>
                        </div>
                        <div className="form-group mt-4">
                            <div className="group">
                                <label className="mandatory" htmlFor="full_name">
                                    Nombre
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="full_name"
                                    placeholder="Nombre"
                                    required
                                    {...register("name", { required: true })}
                                />
                            </div>
                            <div className="group mt-4 mt-md-0">
                                <label className="mandatory" htmlFor="phone">
                                    Celular
                                </label>
                                <input
                                    className="form-control"
                                    type="number"
                                    id="phone"
                                    placeholder="Celular"
                                    required
                                    {...register("phone", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <div className="group">
                                <label className="mandatory">Por donde nos viste?</label>
                                <select className="form-select" required {...register("redSocial", { required: true })}>
                                    <option selected value="instagram">
                                        Instagram
                                    </option>
                                    <option value="facebook">Facebook</option>
                                </select>
                            </div>
                            <div className="group mt-4 mt-md-0">
                                <label className="mandatory">Cuando piensas realizar la compra del vehículo</label>
                                <select
                                    className="form-select"
                                    required
                                    {...register("time_to_buy", { required: true })}
                                >
                                    <option selected value="De 0 a 1 mes">
                                        De 0 a 1 mes
                                    </option>
                                    <option value="De 1 a 3 meses">De 1 a 3 meses</option>
                                    <option value="Más de 3 meses">Más de 3 meses</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <div className="group">
                                <label className="mandatory">¿Cuál es su rango de precio?</label>
                                <select
                                    className="form-select"
                                    required
                                    {...register("price_range", { required: true })}
                                >
                                    <option selected value="Menos de 20 millones">
                                        Menos de 20 millones
                                    </option>
                                    <option value="Entre 20 a 50 millones">Entre 20 a 50 millones</option>
                                    <option value="Entre 50 a 80 millones">Entre 50 a 80 millones</option>
                                    <option value="Más de 80 millones">Más de 80 millones</option>
                                </select>
                            </div>
                            <div className="group mt-4 mt-md-0">
                                <label className="mandatory">¿Está interesado en opciones de financiación?</label>
                                <select className="form-select" required {...register("financing", { required: true })}>
                                    <option value="true">SI</option>
                                    <option value="false">NO</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mt-4">
                            <div className="w-100">
                                <label className="mandatory" htmlFor="city">
                                    ¿En qué ciudad está ubicado?
                                </label>
                                <input
                                    className="form-control"
                                    type="text"
                                    id="city"
                                    placeholder="Ciudad"
                                    required
                                    {...register("city", { required: true })}
                                />
                            </div>
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                            <Button disabled={isDisabled} label="Enviar" type="submit" />
                            {/* <Button
                                label="Quitar"
                                className="ms-3"
                                variant="secondary"
                                onClick={() => setBuyYourCarModalForm(false)}
                            /> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
