import "./expertiseStyles.scss";
import Button from "../../../../../components/Button";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export const Expertise = ({ setModalShowPeritaje, setShowPeritajeImage, idColserauto }) => {
    const { register, handleSubmit, watch } = useForm();

    const onSubmit = handleSubmit(async (data: any) => {
        try {
            const res = await fetch(`https://api.wcar.online/api/peritaje-wiews/create/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    peritaje_id: idColserauto ? idColserauto : "0",
                    name: data?.name || "",
                    phone: data?.phone || "",
                    email: data?.email || "",
                }),
            });
            console.log("funciona", res);
            localStorage.setItem("peritaje", JSON.stringify(data));
            setModalShowPeritaje(false);
            setShowPeritajeImage(true);
        } catch (error) {}
    });

    return (
        <>
            <div className="fixed_container">
                <form onSubmit={onSubmit} className="modal_expertisen">
                    <div>
                        <h5 className="modal-title">Ingresa tus datos para ver el peritaje del vehículo.</h5>
                    </div>
                    <div className="form-group d-flex flex-column mt-4">
                        <label className="mandatory" htmlFor="name">
                            Nombre
                        </label>
                        <input
                            className="form-control"
                            id="name"
                            type="text"
                            required
                            {...register("name", { required: true })}
                        />
                    </div>
                    <div className="form-group d-flex flex-column mt-3">
                        <label className="mandatory" htmlFor="phone">
                            teléfono
                        </label>
                        <input
                            className="form-control"
                            id="phone"
                            type="number"
                            required
                            {...register("phone", { required: true })}
                        />
                    </div>
                    <div className="form-group d-flex flex-column mt-3">
                        <label className="mandatory" htmlFor="email">
                            Correo
                        </label>
                        <input
                            className="form-control"
                            id="email"
                            type="text"
                            required
                            {...register("email", { required: true })}
                        />
                    </div>
                    <div className="mt-3 d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="form-check-input my-0 me-2"
                            {...register("confirmation", { required: true })}
                        />
                        <Link to={"djkshd"}>Acepto la potítica de tratamiento de datos</Link>
                    </div>
                    <div className="mt-4 d-flex justify-content-center gap-4">
                        <Button label="Ver peritaje" type="submit" disabled={!watch("confirmation")} />
                        <Button label="Cancelar" variant="secondary" onClick={() => setModalShowPeritaje(false)} />
                    </div>
                </form>
            </div>
        </>
    );
};
