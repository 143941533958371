import { FC } from "react";
import CardServiceComponentProps from "./CardServiceComponentProps";

const CardServiceComponent: FC<CardServiceComponentProps> = ({ title, description, image }) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-2 text_gray px-5 px-md-3 d-flex align-items-start justify-content-center mb-3">
                    <img
                        src={image}
                        alt="wcar"
                        title="wcar"
                        style={{ width: "32px" }}
                        width={32}
                        height={32}
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div className="col-md-10 text-center text-md-start">
                    <h3 style={{ fontSize: "22px", color: "#1e1e1e" }}>
                        <b>{title}</b>
                    </h3>
                    <span className="text_gray" style={{ fontSize: "16px" }}>
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CardServiceComponent;
