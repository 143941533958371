import { FC } from "react";
import CardVisionComponentProps from "./CardVisionComponentProps";

const CardVisionComponent: FC<CardVisionComponentProps> = ({ minTitle, title, description, image }) => {
    return (
        <div>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-10">
                    <div className="left_line" style={{ fontWeight: 700, color: "#90A3BF" }}>
                        {minTitle}
                    </div>
                </div>
                <div className="col-2 text_gray d-flex align-items-start justify-content-center mb-3">{image}</div>
                <div className="col-10 text-start ">
                    <h3>
                        <b>{title}</b>
                    </h3>
                    <span
                        className="text_gray"
                        style={{
                            fontSize: "16px",
                            color: "#666C89",
                            lineHeight: "24px",
                            fontWeight: "500",
                        }}
                    >
                        {description}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CardVisionComponent;
