import "./cardStyles.scss";

export const CardInsurance = ({ cardInfo }) => {
    return (
        <div className="w-100" style={{ background: cardInfo.cardColor }}>
            <div
                className="card_container"
                style={{
                    flexDirection: cardInfo.directionReverse ? "row-reverse" : "row",
                    color: cardInfo.textColor,
                    width: cardInfo.cardWidth,
                }}
            >
                <img
                    src="./assets/home/car_05.svg"
                    alt="wcar"
                    title="wcar"
                    className="svg_wcar"
                    style={{ right: !cardInfo.directionReverse && "0px", left: cardInfo.directionReverse && "0px" }}
                />

                <div
                    className=" card_part_one sub_title_2 mt-5 mx-0 align-items-center justify-content-center  "
                    style={{ display: cardInfo?.flex ? "flex" : "block" }}
                >
                    <h3 className="text_italic text-white side side_top">
                        {cardInfo.titleBlack} <i>{cardInfo.titleOrange}</i>
                    </h3>
                </div>

                <div className="card_part_two ">
                    <img src={cardInfo.imgUrl} alt="wcar" className="card_phone_img" />
                </div>
            </div>
        </div>
    );
};
