interface WIcon {
    fill?: string;
}

export function Warranty({ fill }: WIcon) {
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.77679 11.1333L6.61127 8.33749L4.41484 6.70832H7.15933L8 3.88334L8.84067 6.70832H11.5852L9.38254 8.33749L10.217 11.1333L8 9.39791L5.77679 11.1333ZM2.64286 20V12.8604C1.96429 12.1854 1.4375 11.3951 1.0625 10.4896C0.6875 9.58403 0.5 8.61528 0.5 7.58333C0.5 5.46944 1.22733 3.67707 2.68199 2.20623C4.13667 0.735411 5.90934 0 8 0C10.0907 0 11.8633 0.735411 13.318 2.20623C14.7727 3.67707 15.5 5.46944 15.5 7.58333C15.5 8.61528 15.3125 9.58403 14.9375 10.4896C14.5625 11.3951 14.0357 12.1854 13.3571 12.8604V20L8 18.375L2.64286 20ZM8 14.0833C9.78571 14.0833 11.3036 13.4514 12.5536 12.1875C13.8036 10.9236 14.4286 9.38888 14.4286 7.58333C14.4286 5.77777 13.8036 4.24305 12.5536 2.97916C11.3036 1.71528 9.78571 1.08333 8 1.08333C6.21429 1.08333 4.69643 1.71528 3.44643 2.97916C2.19643 4.24305 1.57143 5.77777 1.57143 7.58333C1.57143 9.38888 2.19643 10.9236 3.44643 12.1875C4.69643 13.4514 6.21429 14.0833 8 14.0833ZM3.71429 18.4646L8 17.25L12.2857 18.4646V13.7729C11.6882 14.2174 11.0244 14.5608 10.2943 14.8031C9.56421 15.0455 8.79945 15.1667 8 15.1667C7.20055 15.1667 6.43579 15.0455 5.7057 14.8031C4.97563 14.5608 4.31182 14.2174 3.71429 13.7729V18.4646Z"
                fill={fill}
            />
        </svg>
    );
}
