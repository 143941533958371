export default function ScreenRecord() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.5331 10.6667C28.5331 8.64162 26.8914 7 24.8664 7H7.66667C5.64162 7 4 8.64162 4 10.6667V20.8537C4 22.8788 5.64162 24.5204 7.66666 24.5204H11.7665C12.3188 24.5204 12.7665 24.0727 12.7665 23.5204C12.7665 22.9681 12.3188 22.5204 11.7665 22.5204H7.66666C6.74619 22.5204 6 21.7742 6 20.8537L6 10.6667C6 9.74619 6.74619 9 7.66667 9L24.8664 9C25.7869 9 26.5331 9.74619 26.5331 10.6667V20.8537C26.5331 21.7742 25.7869 22.5204 24.8664 22.5204H21.2665C20.7142 22.5204 20.2665 22.9681 20.2665 23.5204C20.2665 24.0727 20.7142 24.5204 21.2665 24.5204H24.8664C26.8914 24.5204 28.5331 22.8788 28.5331 20.8537V10.6667Z"
                fill="#EFF0FA"
            />
            <path
                d="M10.9436 17.7427C10.4618 18.2244 10.4618 19.0055 10.9436 19.4873C11.4253 19.969 12.2064 19.969 12.6882 19.4873L15.2083 16.9671V26.0853C15.2083 26.7666 15.7606 27.3189 16.4419 27.3189C17.1232 27.3189 17.6756 26.7666 17.6756 26.0853L17.6756 16.9671L20.1957 19.4873C20.6775 19.969 21.4586 19.969 21.9403 19.4873C22.4221 19.0055 22.4221 18.2244 21.9403 17.7427L17.3142 13.1166C17.0829 12.8852 16.7691 12.7552 16.4419 12.7552C16.1148 12.7552 15.801 12.8852 15.5696 13.1166L10.9436 17.7427Z"
                fill="#EFF0FA"
            />
        </svg>
    );
}
