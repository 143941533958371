import "./BannerYourCarStyles.scss";

interface Props {
    text?: string;
}

export default function BannerYourCar({ text }: Props) {
    return (
        <div className="banner">
            <h1>{text || "Camionetas usadas"}</h1>
            <img src="/assets/Lines.svg" alt="Lines" className="lines" />
        </div>
    );
}
