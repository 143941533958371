import { getDatabase } from "@firebase/database";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging"


export const firebaseConfig = {
    apiKey: "AIzaSyCAwgtbH7rzI3NXsP0R6r63LUyzB4KlXjQ",
    authDomain: "wcar-4ef88.firebaseapp.com",
    projectId: "wcar-4ef88",
    storageBucket: "wcar-4ef88.appspot.com",
    messagingSenderId: "594137120507",
    appId: "1:594137120507:web:22e85f261547087a23fa5e",
    measurementId: "G-EZ2ST44FJC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const dataBase = getDatabase(app)
export const messaging = getMessaging(app)