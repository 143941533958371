import React from "react";
import { ButtonProps } from "./ButtonProps";
import "./ButtonStyles.scss";

export default function Button({
    iconRight,
    variant = "primary",
    label,
    className,
    onClick,
    size = "big",
    type = "button",
    disabled = false,
    width,
    withOutBorder,
}: ButtonProps) {
    return (
        <button
            disabled={disabled}
            className={`btn-custom ${
                variant === "cyan"
                    ? "btn-cyan"
                    : variant === "secondary"
                    ? "btn-secondary"
                    : variant === "black"
                    ? "btn-black"
                    : variant === "tertiary" && "btn-tertiary"
            } ${size === "medium" && "btn-medium"} ${className} ${withOutBorder && "without-border"}`}
            onClick={() => {
                onClick && onClick();
            }}
            type={type}
            style={{
                minWidth: width,
                maxWidth: width,
                width,
            }}
        >
            {label}
            {iconRight && (
                <div
                    style={{
                        width: "40px",
                        marginLeft: "8px",
                    }}
                >
                    {iconRight}
                </div>
            )}
        </button>
    );
}
