import "react-slideshow-image/dist/styles.css";
import "./slideShowImageStyles.scss";
import { Slide } from "react-slideshow-image";
import { useRef, useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

export const SlideShowImage = ({ images, imageShowing, setOpenZoom }) => {
    const slideRef = useRef(null);
    const [slideCurrentIndex, setSlideCurrentIndex] = useState(imageShowing);

    useEffect(() => {
        slideRef.current.goTo(slideCurrentIndex);
    }, [slideCurrentIndex]); // Added dependency here

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: any) => {
        switch (event.keyCode) {
            case 27:
                setOpenZoom(false);
                break;
            case 37:
                previousSlide();
                break;
            case 39:
                nextSlide();
                break;
            default:
                break;
        }
    };

    const previousSlide = () => {
        setSlideCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
    };

    const nextSlide = () => {
        setSlideCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex));
    };

    return (
        <div className="slide-container">
            <button
                onClick={previousSlide}
                className="custom-arrow-prev"
                style={{ position: "absolute", top: 0, zIndex: 1, bottom: 0, margin: "auto" }}
            >
                <IoIosArrowBack />
            </button>
            <button
                onClick={nextSlide}
                className="custom-arrow-next"
                style={{ position: "absolute", top: 0, zIndex: 1, bottom: 0, margin: "auto" }}
            >
                <IoIosArrowForward />
            </button>

            <Slide
                ref={slideRef}
                autoplay={false}
                arrows={false}
                transitionDuration={100}
                infinite={false}
                onChange={(prev, next) => {
                    setSlideCurrentIndex(next);
                }}
            >
                {images.map((image: string, i: number) => (
                    <div key={i}>
                        <img src={image} className="images_styles" alt="wcar" />
                    </div>
                ))}
            </Slide>
        </div>
    );
};
