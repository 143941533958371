import "./spinerStyles.scss"

export const Spiner = () => {
    return (
        <>
            <div className="div-loader" style={{ height: "100%", minHeight: "100vh" }}>
                <p
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "auto",
                        marginTop: 400,
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                </p>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 400,
                        margin: "auto",
                        marginTop: 10,
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                >
                    <span className="loader"></span>
                </div>
            </div>
        </>
    )
}