interface ItemListProps {
    title: string
    content: string | React.ReactNode
    icon: string
    onClick?: () => void
}

export const ItemList = ({ title, content, icon, onClick }: ItemListProps) => {
    return (
        <li className="mb-3 mb-md-4 d-flex align-items-center">
            <img
                style={{ width: "30px", height: "30px" }}
                src={icon}
                className="img-fluid me-2"
                alt="wcar"
                title="wcar"
            />
            <span className="d-block" onClick={onClick && onClick}>
                {title}
                <br />
                <strong className="text-black">{content}</strong>
            </span>
        </li>
    )
}