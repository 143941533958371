export function Alert({ height = 16, width = 16 }) {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.45008 10.1667V10.2167H6.50008H7.50005H7.55005V10.1667V6.33334V6.28334H7.50005H6.50008H6.45008V6.33334V10.1667ZM6.58106 4.23487L6.61641 4.27022L6.58106 4.23487C6.46802 4.34792 6.41161 4.48872 6.41161 4.65387C6.41161 4.81903 6.46802 4.95984 6.58106 5.07288C6.6941 5.18592 6.83491 5.24232 7.00006 5.24232C7.16522 5.24232 7.30603 5.18592 7.41907 5.07288C7.53211 4.95984 7.58852 4.81903 7.58852 4.65387C7.58852 4.48872 7.53211 4.34792 7.41907 4.23487C7.30603 4.12183 7.16522 4.06542 7.00006 4.06542C6.83491 4.06542 6.6941 4.12183 6.58106 4.23487ZM4.51131 12.8806C5.28859 13.2158 6.11872 13.3833 7.00118 13.3833C7.88365 13.3833 8.71361 13.2158 9.49055 12.8803C10.2665 12.5454 10.942 12.0905 11.5166 11.5157C12.0911 10.9409 12.5458 10.2651 12.8806 9.48876C13.2159 8.71148 13.3834 7.88135 13.3834 6.99889C13.3834 6.11642 13.2158 5.28646 12.8804 4.50952C12.5454 3.73354 12.0905 3.05804 11.5157 2.48349C10.9409 1.90895 10.2651 1.45428 9.48882 1.11944L9.46914 1.16506L9.48882 1.11944C8.71154 0.784184 7.88141 0.616687 6.99895 0.616687C6.11648 0.616687 5.28652 0.784257 4.50958 1.11967C3.7336 1.45466 3.0581 1.90953 2.48355 2.48434C1.90901 3.05914 1.45435 3.73493 1.1195 4.51125C0.784244 5.28853 0.616748 6.11866 0.616748 7.00112C0.616748 7.88359 0.784318 8.71355 1.11973 9.49049C1.45472 10.2665 1.90959 10.942 2.4844 11.5165C3.0592 12.0911 3.73499 12.5457 4.51131 12.8806ZM10.748 10.748C9.72428 11.7717 8.47597 12.2833 7.00006 12.2833C5.52416 12.2833 4.27585 11.7717 3.25209 10.748C2.22833 9.72422 1.71673 8.47591 1.71673 7C1.71673 5.5241 2.22833 4.27579 3.25209 3.25203C4.27585 2.22827 5.52416 1.71667 7.00006 1.71667C8.47597 1.71667 9.72428 2.22827 10.748 3.25203C11.7718 4.27579 12.2834 5.5241 12.2834 7C12.2834 8.47591 11.7718 9.72422 10.748 10.748Z"
                fill="#EC671B"
                stroke="#EC671B"
                stroke-width="0.1"
            />
        </svg>
    );
}
